/* eslint-disable import/prefer-default-export */
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getColorFilterConfig } from '../filters/color';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getVintageFilterConfig } from '../filters/vintage';

const heathCeramicsConditionsOptions = [
  {
    key: Conditions.NewWithTags,
    label: 'New with Sticker',
    description: 'Your item has never been used. There are no quirks or issues whatsoever.',
    hideConditionFromListingFlow: true,
  },
  {
    key: Conditions.NewWithoutTags,
    label: 'New',
    description: 'Your item has never been used. There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'You’ve used this item and it’s well maintained. There may be some quirks like a small chip, or minor discoloration, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const heathCeramicsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({
      options: heathCeramicsConditionsOptions,
    }),
    getColorFilterConfig(),
    getBrandDirectFilterConfig(),
    getVintageFilterConfig(),
  ],
};
