/* eslint-disable camelcase */
import isEqual from 'lodash/isEqual';
import { Dispatch } from 'redux';
import { omit } from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { getCurrencyConfig } from '../../hooks/useCountryConfig';
import {
  DEFAULT_SOLD_LISTINGS_PAGE_SIZE,
  getCombinedSoldAndOpenListingUuids,
  getTopSoldListings,
} from '../../util/ducks/soldListings';
import { Listing, ListingItemType } from '../../types/sharetribe/listing';
import { Uuid } from '../../../server/types/sharetribe/uuid';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import config from '../../shopConfig/config';
import { getEnabledCustomerExperiences, getShopConfig } from '../../shopConfig/configHelper';
import { StorableError } from '../../types/error';
import { Filter } from '../../types/filters/filters';
import { RequestStatus } from '../../types/requestStatus';
import { Pagination } from '../../types/sharetribe/pagination';
import { ShopConfigV2 } from '../../types/shopConfig/shopConfigV2';
import { ITEM_AVAILABILITY_AVAILABLE } from '../../util/constants';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { Feature, isFeatureEnabled } from '../../util/featureFlags';
import { getDataFromFrenzySearch, orderIdsByList } from '../../util/frenzyHelpers';
import { handle } from '../../util/helpers';
import * as log from '../../util/log';
import { getShouldFetchSoldListings, shouldShowListItemCard } from '../../util/search';
import { ModalParams, parse } from '../../util/urlHelpers';
import { hasIntersection } from '../../util/utilityFunctions';
import { updateGeneralSavedSearchEmailSubscription } from '../../ducks/user.duck';
import { UpdateGeneralSavedSearchEmailSubscriptionParams } from '../../types/models/savedSearch';
import { CountryCode } from '../../types/apollo/generated/types.generated';

const RESULT_PAGE_SIZE = 24;

interface SearchListingsSuccessInputs {
  listingIds: Uuid[];
  pagination: Pagination;
  searchApiFilters?: Filter[];
}

// ================ Action types ================ //

const SEARCH_LISTINGS_REQUEST = 'app/LandingPageV2/SEARCH_LISTINGS_REQUEST';
const SEARCH_LISTINGS_SUCCESS = 'app/LandingPageV2/SEARCH_LISTINGS_SUCCESS';
const SEARCH_LISTINGS_ERROR = 'app/LandingPageV2/SEARCH_LISTINGS_ERROR';

const SOLD_LISTINGS_REQUEST = 'app/LandingPageV2/SOLD_LISTINGS_REQUEST';
const SOLD_LISTINGS_SUCCESS = 'app/LandingPageV2/SOLD_LISTINGS_SUCCESS';
const SOLD_LISTINGS_ERROR = 'app/LandingPageV2/SOLD_LISTINGS_ERROR';

const UPDATE_NOTIFICATIONS_REQUEST = 'app/LandingPageV2/UPDATE_NOTIFICATIONS_REQUEST';
const UPDATE_NOTIFICATIONS_SUCCESS = 'app/LandingPageV2/UPDATE_NOTIFICATIONS_SUCCESS';
const UPDATE_NOTIFICATIONS_ERROR = 'app/LandingPageV2/UPDATE_NOTIFICATIONS_ERROR';

interface SearchListingsRequest {
  type: typeof SEARCH_LISTINGS_REQUEST;
  searchParams: any;
}

interface SearchListingsSuccess {
  type: typeof SEARCH_LISTINGS_SUCCESS;
  listingIds: Uuid[];
  pagination: Pagination;
  searchApiFilters?: Filter[];
}

interface SearchListingsError {
  type: typeof SEARCH_LISTINGS_ERROR;
  error: StorableError;
}

interface SoldListingsRequest {
  type: typeof SOLD_LISTINGS_REQUEST;
}

interface SoldListingsSuccess {
  type: typeof SOLD_LISTINGS_SUCCESS;
  listingIds: Uuid[];
}

interface SoldListingsError {
  type: typeof SOLD_LISTINGS_ERROR;
  error: StorableError;
}

interface UpdateNotificationsRequest {
  type: typeof UPDATE_NOTIFICATIONS_REQUEST;
}

interface UpdateNotificationsSuccess {
  type: typeof UPDATE_NOTIFICATIONS_SUCCESS;
}

interface UpdateNotificationsError {
  type: typeof UPDATE_NOTIFICATIONS_ERROR;
}

type LandingPageV2ActionType =
  | SearchListingsRequest
  | SearchListingsSuccess
  | SearchListingsError
  | SoldListingsRequest
  | SoldListingsSuccess
  | SoldListingsError
  | UpdateNotificationsRequest
  | UpdateNotificationsSuccess
  | UpdateNotificationsError;

// ================ Reducer ================ //

export interface LandingPageV2State {
  pagination: Pagination | null;
  searchParams: any | null;
  searchListingsStatus: RequestStatus;
  searchListingsError: StorableError | null;
  soldListingsStatus: RequestStatus;
  soldListingsError: StorableError | null;
  currentPageResultIds: Uuid[];
  searchApiFilters?: Filter[];
  updateNotificationsStatus: RequestStatus;
}

const initialState = {
  pagination: null,
  searchParams: null,
  searchListingsStatus: RequestStatus.Ready,
  searchListingsError: null,
  soldListingsStatus: RequestStatus.Ready,
  soldListingsError: null,
  currentPageResultIds: [],
  searchApiFilters: undefined,
  updateNotificationsStatus: RequestStatus.Ready,
};

export default function LandingPageV2Reducer(
  state: LandingPageV2State = initialState,
  action: LandingPageV2ActionType
): LandingPageV2State {
  switch (action.type) {
    case SEARCH_LISTINGS_REQUEST: {
      return {
        ...state,
        searchParams: action.searchParams,
        searchListingsStatus: RequestStatus.Pending,
        searchListingsError: null,
        // Reset the sold listings so that we know to refetch them again
        soldListingsStatus: RequestStatus.Ready,
        soldListingsError: null,
      };
    }
    case SEARCH_LISTINGS_SUCCESS: {
      return {
        ...state,
        searchListingsStatus: RequestStatus.Success,
        currentPageResultIds: action.listingIds,
        pagination: action.pagination,
        searchApiFilters: action.searchApiFilters,
      };
    }
    case SEARCH_LISTINGS_ERROR: {
      return {
        ...state,
        searchListingsStatus: RequestStatus.Error,
        searchListingsError: action.error,
      };
    }
    case SOLD_LISTINGS_REQUEST: {
      return {
        ...state,
        soldListingsStatus: RequestStatus.Pending,
        soldListingsError: null,
      };
    }
    case SOLD_LISTINGS_SUCCESS: {
      return {
        ...state,
        soldListingsStatus: RequestStatus.Success,
        currentPageResultIds: action.listingIds,
      };
    }
    case SOLD_LISTINGS_ERROR: {
      return {
        ...state,
        soldListingsStatus: RequestStatus.Error,
        soldListingsError: action.error,
      };
    }
    case UPDATE_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        updateNotificationsStatus: RequestStatus.Pending,
      };
    }
    case UPDATE_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        updateNotificationsStatus: RequestStatus.Success,
      };
    }
    case UPDATE_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        updateNotificationsStatus: RequestStatus.Error,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const searchListingsRequest = (searchParams: any) => ({
  type: SEARCH_LISTINGS_REQUEST,
  searchParams,
});

const searchListingsSuccess = (params: SearchListingsSuccessInputs) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  listingIds: params.listingIds,
  pagination: params.pagination,
  searchApiFilters: params.searchApiFilters,
});

const searchListingsError = (error: StorableError) => ({ type: SEARCH_LISTINGS_ERROR, error });

const soldListingsRequest = () => ({ type: SOLD_LISTINGS_REQUEST });

const soldListingsSuccess = (listingIds: Uuid[]) => ({ type: SOLD_LISTINGS_SUCCESS, listingIds });

const soldListingsError = (error: StorableError) => ({ type: SOLD_LISTINGS_ERROR, error });

const updateNotificationsRequest = () => ({ type: UPDATE_NOTIFICATIONS_REQUEST });

const updateNotificationsSuccess = () => ({ type: UPDATE_NOTIFICATIONS_SUCCESS });

const updateNotificationsError = () => ({ type: UPDATE_NOTIFICATIONS_ERROR });

const buildSharetribeQueryParams = (
  searchParams: any,
  treetId: string,
  shopConfigV2: ShopConfigV2
) => {
  const {
    filters,
    shopName,
    internationalConfig: { allowedOriginToDestinationCountries },
  } = getShopConfig(treetId, shopConfigV2);

  const countryCode = (Object.keys(allowedOriginToDestinationCountries)[0] ||
    CountryCode.Us) as CountryCode;
  const currencyConfig = getCurrencyConfig(countryCode);

  const priceSearchParams = (priceParam: string) => {
    const inSubunits = (value: any) =>
      convertUnitToSubUnit(value, unitDivisor(currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const { perPage, price, sort, pub_tags, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);

  const shopFilter = { pub_shopName: shopName, pub_availability: ITEM_AVAILABILITY_AVAILABLE };

  // Find if any conflicting filters are set as on
  const { conflictingFilters } = config.custom.sortConfig;
  const conflictingQueries = conflictingFilters.reduce((acc, conflictingFilter) => {
    const filterConfig = filters?.find((filter: any) => filter.id === conflictingFilter);
    return acc.concat(filterConfig?.queryParamNames);
  }, []);

  const hasConflictingFilters = hasIntersection(conflictingQueries, Object.keys(searchParams));

  return {
    ...rest,
    ...priceMaybe,
    ...shopFilter,
    ...(pub_tags && { pub_tags: `has_any: ${pub_tags}` }),
    per_page: perPage,
    // If theres conflicting filters (keyword) we don't need to sort,
    // otherwise default is updatedAt filter
    sort: hasConflictingFilters ? undefined : sort || 'pub_updatedAt',
    pub_listingItemType: ListingItemType.Marketplace,
  };
};

/* ================ Thunks ================ */

export const searchListings =
  (queryParams: any) => async (dispatch: Dispatch, getState: () => any, sdk: any) => {
    const { searchParams: currentSearchParams } = getState().LandingPageV2;

    const { page = 1, ...rest } = queryParams;
    const searchParams = {
      ...rest,
      page,
      perPage: RESULT_PAGE_SIZE,
      pub_deleted: false,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.default'],
      'limit.images': 1,
    };

    // Don't need to refetch if it's the same call. Prevents client side from
    // refetching after listings are fetched once during SSR
    if (isEqual(currentSearchParams, searchParams)) {
      return getState().LandingPageV2;
    }

    dispatch(searchListingsRequest(searchParams));

    const { treetId, origin, shopConfig: shopConfigV2 } = getState().initial;
    const { currentUser } = getState().user;
    const isFrenzySearchEnabled = isFeatureEnabled(Feature.FrenzySearch, treetId, currentUser);

    // By default, we will use the sharetribe query params to search
    let params = buildSharetribeQueryParams(searchParams, treetId, shopConfigV2);
    let pagination;
    let filters;

    // If Frenzy Search is enabled, we will override the sharetribe query params with the listing ids
    // returned from Frenzy's Search API, along with pagination info and filters
    if (isFrenzySearchEnabled) {
      const { filters: filtersConfig } = getShopConfig(treetId, shopConfigV2);
      const frenzySearchData = await getDataFromFrenzySearch({
        subdomain: treetId,
        searchParams,
        filtersConfig,
        origin,
        userId: currentUser?.id.uuid,
      });

      const {
        params: sharetribeListingIdsQuery,
        pagination: searchApiPagination,
        filters: searchApifilters,
      } = frenzySearchData;
      params = sharetribeListingIdsQuery || params;
      pagination = searchApiPagination;
      filters = searchApifilters;
    }

    const [response, error] = await handle(sdk.listings.query(params));

    if (error) {
      error.message = `[Search Listings] ${error.message}`;
      log.error(error, 'query-search-landing-page-listings-failed', {
        searchParams,
        params,
      });
      dispatch(searchListingsError(storableError(error)));
      throw error;
    }

    dispatch(addMarketplaceEntities(response));
    const openListings = denormalisedResponseEntities(response);

    // If ids param is present, preserve the order of listing ids queried
    const orderedOpenListings = params.ids
      ? orderIdsByList(openListings, params.ids.split(','))
      : openListings;

    const searchListingsPagination = pagination || response?.data.meta;

    const searchListingsPayload = {
      listingIds: orderedOpenListings.map((l) => l.id),
      pagination: searchListingsPagination,
      ...(!!filters && { searchApiFilters: filters }),
    };
    dispatch(searchListingsSuccess(searchListingsPayload));
    return response;
  };

export const fetchAndInsertSoldListings = () => async (dispatch: Dispatch, getState: () => any) => {
  const { currentPageResultIds, searchParams, pagination } = getState().LandingPageV2;
  const shouldFetchSoldListings = getShouldFetchSoldListings(searchParams);

  // Don't fetch sold listings if any filters/search/sorts are applied
  if (!shouldFetchSoldListings) return null;

  dispatch(soldListingsRequest());

  const { treetId, origin, shopConfig: shopConfigV2 } = getState().initial;

  let soldListings: Listing[] = [];
  const { page } = searchParams;
  const perPage =
    DEFAULT_SOLD_LISTINGS_PAGE_SIZE -
    (currentPageResultIds.length % DEFAULT_SOLD_LISTINGS_PAGE_SIZE);
  const [soldResponse, soldError] = await handle(getTopSoldListings(page, perPage, origin));

  if (soldError) {
    soldError.message = `[Fetch and Insert Sold Listings] ${soldError.message}`;
    log.error(soldError, 'landing-page-v2-fetch-sold-listings-failed', {});
    dispatch(soldListingsError(storableError(soldError)));
    // Swallow error since it's not necessary to render the sold listings
    return null;
  }

  dispatch(addMarketplaceEntities(soldResponse));
  soldListings = denormalisedResponseEntities(soldResponse);

  const { enabledCustomerExperiences } = getShopConfig(treetId, shopConfigV2);
  const { allowSell } = getEnabledCustomerExperiences(enabledCustomerExperiences);

  const isFirstPage = page === 1;
  const hasListItemCard = shouldShowListItemCard(isFirstPage, allowSell, searchParams);
  const numListingsToExclude = hasListItemCard ? 1 : 0;
  const numSoldListingsToInclude = perPage - numListingsToExclude;

  const combinedListingIds = getCombinedSoldAndOpenListingUuids(
    currentPageResultIds,
    soldListings.map((listing) => listing.id),
    numSoldListingsToInclude,
    page,
    pagination.totalPages
  );

  dispatch(soldListingsSuccess(combinedListingIds));
  return soldResponse;
};

export const updateNotifications =
  (params: UpdateGeneralSavedSearchEmailSubscriptionParams) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(updateNotificationsRequest());

    try {
      const response = await dispatch(updateGeneralSavedSearchEmailSubscription(params));
      dispatch(updateNotificationsSuccess());
      return response;
    } catch (e) {
      // Error is already logged
      dispatch(updateNotificationsError());
      return null;
    }
  };

export const loadData = (params: any, search: string) => {
  const queryParams = parse(search);
  const searchParams = omit(queryParams, Object.values(ModalParams));

  return searchListings(searchParams);
};
