import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ShippingLabelProvider } from '@prisma/client';
import {
  Button,
  ConditionalWrapper,
  ExternalLink,
  IconCashFilled,
  IconCheckCircleFilled,
  IconShippingLabelFilled,
  InlineTextButton,
  NamedLink,
} from '..';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { getOwnListingsById } from '../../containers/ManageSalesPage/ManageSalesPage.duck';
import { useShopConfig, useShopConfigV2 } from '../../hooks/shopConfig';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import {
  BundleStatus,
  BundleType,
  ItemStatus,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import { OwnListing } from '../../types/sharetribe/listing';
import { FulfillmentMethod } from '../../types/shopConfig/shopConfigV2';
import css from './BundleActions.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

interface SellerActionsProps {
  bundle: BundleInfo;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
}

const SellerActions: FC<SellerActionsProps> = (props) => {
  const { bundle, onGetPaidClick, onMarkAsFulfilledClick } = props;
  const { fulfillment, id, isMarkedFulfilled, bundleItems } = bundle;

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { isBrand } = useCurrentUserPermissions();

  const firstListingId = bundleItems?.[0]?.listing.sharetribeListingId;
  const ownListings = useSelector<any>((state) =>
    getOwnListingsById(state, [new UUID(firstListingId)])
  ) as OwnListing[];

  // If the listing author has a defined fulfillmentMethod value, use that to control
  // the fulfillment settings. Otherwise, we will use the computed hasFulfillmentOffTreet flag
  // for brand direct items by default.
  const userFulfillmentMethod = currentUser?.attributes?.profile?.publicData?.fulfillmentMethod;
  const { defaultBrandFulfillmentMethod, css: shopCss } = useShopConfigV2();
  const fulfillmentMethod =
    userFulfillmentMethod || (isBrand ? defaultBrandFulfillmentMethod : FulfillmentMethod.Treet);

  // Ignore actions if a brand isn't fulfilling through Treet's dash
  if (fulfillmentMethod === FulfillmentMethod.Shopify) return null;

  const handlePrintShippingLabelClick = () => {
    const labelUrl = fulfillment?.labelURL || '';
    window.open(labelUrl, '_blank');
  };

  const hasShippingLabel = !!fulfillment?.labelURL;
  const canRecreateLabel =
    !!fulfillment && fulfillment.shippingLabelProvider !== ShippingLabelProvider.SUPER_CIRCLE;

  const handleClickWithPreventDefault =
    (onClickHandler: (bundle: BundleInfo) => void) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onClickHandler(bundle);
    };

  if (bundle.status === BundleStatus.Open && !isMarkedFulfilled) {
    return (
      <>
        {(fulfillment || isBrand) && onMarkAsFulfilledClick && (
          <Button
            className={css.actionButton}
            onClick={handleClickWithPreventDefault(onMarkAsFulfilledClick)}
          >
            <IconCheckCircleFilled
              className={css.actionIcon}
              color={shopCss?.primaryButton?.font?.color}
            />
            {isBrand ? 'Mark as Fulfilled' : 'Mark as Shipped'}
          </Button>
        )}
        <ConditionalWrapper
          condition={!hasShippingLabel}
          wrapper={(children) => (
            <NamedLink
              name="GenerateShippingLabelPage"
              params={{ id }}
              style={{ textDecoration: 'none' }}
            >
              {children}
            </NamedLink>
          )}
        >
          <Button
            className={css.actionButton}
            onClick={!hasShippingLabel ? undefined : handlePrintShippingLabelClick}
          >
            <IconShippingLabelFilled
              className={css.actionIcon}
              color={shopCss?.primaryButton?.font?.color}
            />
            Print Shipping Label
          </Button>
        </ConditionalWrapper>
        {hasShippingLabel && canRecreateLabel && (
          <Box display="flex" justifyContent="center" pt={3} pb={1}>
            <NamedLink name="GenerateShippingLabelPage" params={{ id }} className={css.link}>
              <Typography variant="body1">Recreate Shipping Label</Typography>
            </NamedLink>
          </Box>
        )}
      </>
    );
  }

  const hasVerifiedBundleItems = bundle.bundleItems.some(
    (bundleItem) => bundleItem.status === ItemStatus.Verified
  );
  const payoutOption = ownListings?.[0]?.attributes?.privateData?.payoutOption;

  if (
    bundle.status === BundleStatus.Resolved &&
    hasVerifiedBundleItems &&
    !payoutOption &&
    onGetPaidClick
  ) {
    return (
      <Button className={css.actionButton} onClick={handleClickWithPreventDefault(onGetPaidClick)}>
        <IconCashFilled className={css.actionIcon} color="inherit" />
        Get Paid!
      </Button>
    );
  }

  return null;
};

interface BuyerActionsProps {
  bundle: BundleInfo;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
}

const BuyerActions: FC<BuyerActionsProps> = (props) => {
  const { bundle, onDisputeClick, onVerifyClick, onMarkAsDeliveredClick } = props;
  const { tryOnReturnLink } = useShopConfig();
  const { isTradeInAdmin } = useCurrentUserPermissions();

  const isTradeInBundle = bundle?.type === BundleType.TradeIn;
  const isDelivered = bundle.status === BundleStatus.Delivered;
  const isOpenOrShipped = [BundleStatus.Open, BundleStatus.Shipped].includes(bundle.status);
  const isTradeInAdminAndBundle = isTradeInAdmin && isTradeInBundle;
  const { isBrandDirect } = bundle;
  const shouldDisplayTryOnReturnLink = isBrandDirect && tryOnReturnLink;

  return (
    <Box pt="4px">
      {isOpenOrShipped && isTradeInAdmin && isTradeInBundle && onMarkAsDeliveredClick && (
        <Button className={css.actionButton} onClick={() => onMarkAsDeliveredClick(bundle)}>
          <IconCheckCircleFilled className={css.actionIcon} color="inherit" />
          Mark as Delivered
        </Button>
      )}
      {isDelivered && !isTradeInBundle && onVerifyClick && (
        <Button className={css.actionButton} onClick={() => onVerifyClick(bundle)}>
          <IconCheckCircleFilled className={css.actionIcon} color="inherit" />
          Verify Purchase
        </Button>
      )}
      {isDelivered && onDisputeClick && isTradeInAdminAndBundle && (
        <Button className={css.actionButton} onClick={() => onDisputeClick(bundle)}>
          Issue Credit
        </Button>
      )}

      {isDelivered && onDisputeClick && !isTradeInAdminAndBundle && (
        <Box display="flex" justifyContent="center" mt={2} mb={1}>
          {!shouldDisplayTryOnReturnLink && (
            <InlineTextButton className={css.link} onClick={() => onDisputeClick(bundle)}>
              File A Misrepresentation Claim
            </InlineTextButton>
          )}
          {shouldDisplayTryOnReturnLink && (
            <ExternalLink href={tryOnReturnLink} className={css.link}>
              <TypographyWrapper variant="body1">Start Your Brand Direct Return</TypographyWrapper>
            </ExternalLink>
          )}
        </Box>
      )}
    </Box>
  );
};

interface BundleActionsProps {
  bundle: BundleInfo;
  participant: Participant;
  onVerifyClick?: (bundle: BundleInfo) => void;
  onDisputeClick?: (bundle: BundleInfo) => void;
  onGetPaidClick?: (bundle: BundleInfo) => void;
  onMarkAsFulfilledClick?: (bundle: BundleInfo) => void;
  onMarkAsDeliveredClick?: (bundle: BundleInfo) => void;
}

// TODO(SY | TREET-718): Refactor this to be used on the ManageSalesMobile page
const BundleActions: FC<BundleActionsProps> = (props) => {
  const {
    bundle,
    participant,
    onVerifyClick,
    onDisputeClick,
    onGetPaidClick,
    onMarkAsFulfilledClick,
    onMarkAsDeliveredClick,
  } = props;
  const isBuyer = participant === Participant.Buyer;
  const isSeller = participant === Participant.Seller;

  if (isSeller) {
    return (
      <SellerActions
        bundle={bundle}
        onGetPaidClick={onGetPaidClick}
        onMarkAsFulfilledClick={onMarkAsFulfilledClick}
      />
    );
  }
  if (isBuyer) {
    return (
      <BuyerActions
        bundle={bundle}
        onDisputeClick={onDisputeClick}
        onVerifyClick={onVerifyClick}
        onMarkAsDeliveredClick={onMarkAsDeliveredClick}
      />
    );
  }

  return null;
};

export default BundleActions;
