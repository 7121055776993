import React from 'react';
import { isEmpty } from 'lodash';
import { string } from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
  BuilderSection,
  ExternalLink,
  FormattedMessage,
  IconSocialMedia,
  Logo,
  NamedLink,
  TextWithLineBreaks,
  TypographyWrapper,
} from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useStyleType } from '../../hooks/useStyleType';
import { AboutPageTab } from '../../containers/AboutPage/aboutPageUtils';
import { IconSocialMediaType } from '../Icons/IconSocialMedia/IconSocialMedia';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useActionCopy } from '../../hooks/useActionCopy';
import css from './Footer.module.css';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { BuilderSections } from '../../util/builder';
import { getPercentAlteredHexColor } from '../../util/colors/colors';

const renderSocialMediaLinks = (intl) => {
  const { socialMediaConfig } = useShopConfig();
  const { instagramLink, facebookLink, pinterestLink, tiktokLink, youtubeLink, xLink } =
    socialMediaConfig || {};

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTiktok = intl.formatMessage({ id: 'Footer.goToTiktok' });
  const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });
  const goToX = intl.formatMessage({ id: 'Footer.goToX' });

  const facebookLinkEl = facebookLink ? (
    <ExternalLink key="linkToFacebook" href={facebookLink} className={css.icon} title={goToFb}>
      <IconSocialMedia type={IconSocialMediaType.Facebook} />
    </ExternalLink>
  ) : null;

  const tiktokEl = tiktokLink ? (
    <ExternalLink key="linkToTiktok" href={tiktokLink} className={css.icon} title={goToTiktok}>
      <IconSocialMedia type={IconSocialMediaType.Tiktok} />
    </ExternalLink>
  ) : null;

  const instagramLinkEl = instagramLink ? (
    <ExternalLink key="linkToInstagram" href={instagramLink} className={css.icon} title={goToInsta}>
      <IconSocialMedia type={IconSocialMediaType.Instagram} />
    </ExternalLink>
  ) : null;

  const pinterestEl = pinterestLink ? (
    <ExternalLink
      key="linkToPinterest"
      href={pinterestLink}
      className={css.icon}
      title={goToPinterest}
    >
      <IconSocialMedia type={IconSocialMediaType.Pinterest} />
    </ExternalLink>
  ) : null;

  const youtubeEl = youtubeLink ? (
    <ExternalLink key="linkToYoutube" href={youtubeLink} className={css.icon} title={goToYoutube}>
      <IconSocialMedia type={IconSocialMediaType.Youtube} />
    </ExternalLink>
  ) : null;

  const xEl = xLink ? (
    <ExternalLink key="linkToX" href={xLink} className={css.icon} title={goToX}>
      <IconSocialMedia type={IconSocialMediaType.X} />
    </ExternalLink>
  ) : null;

  return [tiktokEl, facebookLinkEl, instagramLinkEl, pinterestEl, youtubeEl, xEl].filter(
    (v) => v != null
  );
};

const Footer = (props) => {
  const { rootClassName, className, intl } = props;

  const shopConfig = useShopConfig();
  const { allowBuy, allowSell, allowTradeIn, allowList, isListTradeInOnly } =
    useEnabledCustomerExperiences();
  const { isCondensedStyle } = useStyleType();
  const { listActionCopy } = useActionCopy();
  const isMobile = useIsMobile();
  const isCookieConsentEnabled = useFeatureFlags(Feature.CookieConsent);

  const { builderConfig } = shopConfig;
  const footerBuilderSectionId = builderConfig?.sections?.[BuilderSections.FooterContent];

  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const {
    shopName,
    copy,
    css: brandCss,
    enabledAboutTreetPages = [],
    additionalFooterSections,
  } = shopConfig;
  const shouldUseAboutTreetPages = !isEmpty(enabledAboutTreetPages);
  const darkerGrayFooterLinks = getPercentAlteredHexColor(
    brandCss?.backgroundColor || '#FFFFFF',
    -60
  );

  const organizationDescriptionDefault = allowSell ? (
    <FormattedMessage id="Footer.organizationDescription" values={{ shopName }} />
  ) : (
    <FormattedMessage id="Footer.organizationDescriptionBrandDirectOnly" values={{ shopName }} />
  );

  if (footerBuilderSectionId) {
    return (
      <BuilderSection
        sectionType={BuilderSections.FooterContent}
        sectionId={footerBuilderSectionId}
      />
    );
  }

  return (
    <Box className={classes} bgcolor={brandCss?.backgroundColor}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLinksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink
                name="LandingPage"
                className={classNames(css.logoLink, { [css.condensedLogoLink]: isCondensedStyle })}
              >
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <div
                  className={classNames(css.organizationDescription, {
                    [css.condensedOrganizationDescription]: isCondensedStyle,
                  })}
                >
                  <TypographyWrapper variant="body2">
                    <TextWithLineBreaks
                      text={
                        copy?.brandFooterSubtitle ||
                        copy?.heroSubtitle ||
                        organizationDescriptionDefault
                      }
                    />
                  </TypographyWrapper>
                </div>
              </div>
            </div>
            <div style={{ order: 2, display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  {allowList && (
                    <li className={css.listItem}>
                      <NamedLink
                        name={isListTradeInOnly ? 'ManageTradeInsPage' : 'NewListingPage'}
                        className={css.link}
                      >
                        <TypographyWrapper variant="body1">{listActionCopy}</TypographyWrapper>
                      </NamedLink>
                    </li>
                  )}
                  <li className={css.listItem}>
                    <NamedLink
                      name={shouldUseAboutTreetPages ? 'AboutTreetPage' : 'AboutPage'}
                      {...(!shouldUseAboutTreetPages && {
                        params: { tab: AboutPageTab.AboutTreet },
                      })}
                      className={css.link}
                    >
                      <TypographyWrapper variant="body1">About Treet</TypographyWrapper>
                    </NamedLink>
                  </li>
                  {allowBuy && (
                    <li className={css.listItem}>
                      <NamedLink
                        className={css.link}
                        name="AboutPage"
                        params={{ tab: AboutPageTab.Buyer }}
                      >
                        <TypographyWrapper variant="body1">Buyer FAQs</TypographyWrapper>
                      </NamedLink>
                    </li>
                  )}
                  {allowSell && (
                    <li className={css.listItem}>
                      <NamedLink
                        className={css.link}
                        name="AboutPage"
                        params={{ tab: AboutPageTab.Seller }}
                      >
                        <TypographyWrapper variant="body1">Seller FAQs</TypographyWrapper>
                      </NamedLink>
                    </li>
                  )}
                  {allowTradeIn && (
                    <li className={css.listItem}>
                      <NamedLink
                        className={css.link}
                        name="AboutPage"
                        params={{ tab: AboutPageTab.TradeIn }}
                      >
                        <TypographyWrapper variant="body1">Trade-In FAQs</TypographyWrapper>
                      </NamedLink>
                    </li>
                  )}
                  <li className={css.listItem}>
                    <a
                      className={css.link}
                      target="_blank"
                      href="https://treet.zendesk.com/hc/en-us"
                      rel="noreferrer"
                    >
                      <TypographyWrapper variant="body1">Contact</TypographyWrapper>
                    </a>
                  </li>
                  {allowBuy && (
                    <li className={css.listItem}>
                      <NamedLink
                        name="AboutPage"
                        params={{ tab: AboutPageTab.Protection }}
                        className={css.link}
                      >
                        <TypographyWrapper variant="body1">Treet Protection</TypographyWrapper>
                      </NamedLink>
                    </li>
                  )}
                  {additionalFooterSections?.map((footerSection) => (
                    <li key={footerSection.title} className={css.listItem}>
                      <a
                        className={css.link}
                        target="_blank"
                        href={footerSection.link}
                        rel="noreferrer"
                      >
                        <TypographyWrapper variant="body1">{footerSection.title}</TypographyWrapper>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* In their own div so they can both be on the right. When we enable more columns, we can take out this div */}
              <div className={css.extraLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <div>{socialMediaLinks}</div>
                  </li>
                  {/* Dummy list item to preserve spacing */}
                  <li className={css.listItem}>
                    <div />
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <TypographyWrapper variant={isMobile ? 'body2' : 'body1'}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </TypographyWrapper>
                    </NamedLink>
                  </li>
                  {isCookieConsentEnabled && (
                    <li className={css.listItem}>
                      <NamedLink name="CookiePolicyPage" className={css.legalLink}>
                        <TypographyWrapper variant={isMobile ? 'body2' : 'body1'}>
                          <FormattedMessage id="Footer.cookiePolicy" />
                        </TypographyWrapper>
                      </NamedLink>
                    </li>
                  )}
                  <li className={css.listItem}>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <TypographyWrapper variant={isMobile ? 'body2' : 'body1'}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </TypographyWrapper>
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Box className={css.copyrightAndTermsMobile} bgcolor={brandCss?.backgroundColor}>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <TypographyWrapper
                  variant="body2"
                  typographyOverrides={{
                    style: { color: darkerGrayFooterLinks },
                    display: 'inline',
                  }}
                >
                  <FormattedMessage id="Footer.privacy" />
                </TypographyWrapper>
              </NamedLink>
              {isCookieConsentEnabled && (
                <NamedLink name="CookiePolicyPage" className={css.cookiePolicy}>
                  <TypographyWrapper
                    variant="body2"
                    typographyOverrides={{
                      style: { color: darkerGrayFooterLinks },
                      display: 'inline',
                    }}
                  >
                    <FormattedMessage id="Footer.cookiePolicy" />
                  </TypographyWrapper>
                </NamedLink>
              )}
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <TypographyWrapper
                  variant="body2"
                  typographyOverrides={{
                    style: { color: darkerGrayFooterLinks },
                    display: 'inline',
                  }}
                >
                  <FormattedMessage id="Footer.terms" />
                </TypographyWrapper>
              </NamedLink>
            </div>
          </Box>
        </div>
      </div>
    </Box>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
