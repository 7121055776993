/* eslint-disable camelcase */
import { FrenzyApiModes } from '../frenzy/query';

export enum FilterQueryParam {
  keywords = 'keywords',
  price = 'price',
  sort = 'sort',
  pub_condition = 'pub_condition',
  pub_size = 'pub_size',
  pub_isBrandDirect = 'pub_isBrandDirect',
  pub_categories = 'pub_categories',
  pub_category = 'pub_category',
  pub_tags = 'pub_tags',
  style = 'style',
  gender = 'gender',
  color = 'color',
  inseam = 'inseam',
  decade = 'decade',
  vintage = 'vintage',
}

export type SearchFilterQueryParams = {
  [FilterQueryParam.keywords]?: string;
  [FilterQueryParam.price]?: string;
  [FilterQueryParam.sort]?: string;
  [FilterQueryParam.pub_condition]?: string;
  [FilterQueryParam.pub_size]?: string;
  [FilterQueryParam.pub_isBrandDirect]?: boolean;
  [FilterQueryParam.pub_categories]?: string;
  [FilterQueryParam.pub_category]?: string;
  [FilterQueryParam.pub_tags]?: string;
  [FilterQueryParam.inseam]?: string;
  [FilterQueryParam.decade]?: string;
  [FilterQueryParam.vintage]?: string;
  // The below search query params map to FrenzyFacets and are only used for Frenzy
  // enabled searches. These is not stored on Sharetribe listings data.
  [FilterQueryParam.style]?: string;
  [FilterQueryParam.gender]?: string;
  [FilterQueryParam.color]?: string;
};

export type SearchQueryParams = SearchFilterQueryParams & {
  page?: number;
};

// Extra ST SDK listing query params for search results page (that are not already URL query params)
export type SharetribeListingQueryParams = {
  perPage: number;
  include: string[];
  'fields.listing': string[];
  'fields.user': string[];
  'fields.image': string[];
  'limit.images': string[];
};

export type SearchParams = SearchQueryParams &
  SharetribeListingQueryParams & {
    mode: FrenzyApiModes;
  };
