import { useIntl } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { FC } from 'react';
import { AboutPageTab } from '../../containers/AboutPage/aboutPageUtils';
import { useShopConfig } from '../../hooks/shopConfig';
import { NamedLink, StyledAccordion, TypographyWrapper } from '..';
import { FAQ, FAQObject, FontConfigSection } from '../../types/shopConfig/shopConfigV2';
import { ShipDeadline } from '../../util/shipping';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useReturnInsurance } from '../../hooks/useReturnInsurance';
import { SEEL_RETURN_ASSURANCE } from '../../util/seelHelpers';
import css from './BuyerFAQ.module.css';
import { useBrandCountryConfig, useUserCountryConfig } from '../../hooks/useCountryConfig';
import { CountryCode } from '../../types/apollo/generated/types.generated';
import { formatDollarAmount } from '../../util/currency';
import MarkdownText from '../MarkdownText/MarkdownText';
import { isUkOrEUShop } from '../../util/shopConfig';

interface FAQTextProps {
  children: string;
}

const FAQTitle: FC<FAQTextProps> = ({ children }) => {
  const { fontConfig } = useShopConfig();
  const typographyVariant = fontConfig[FontConfigSection.AboutPageFAQTitle];

  return (
    <TypographyWrapper
      variant={typographyVariant}
      typographyOverrides={{ style: { fontWeight: 'bold' } }}
    >
      {children}
    </TypographyWrapper>
  );
};

const FAQDescription: FC<FAQTextProps> = ({ children }) => (
  <TypographyWrapper variant="body1" typographyOverrides={{ style: { whiteSpace: 'pre-wrap' } }}>
    <MarkdownText markdownText={children} />
  </TypographyWrapper>
);

const RefundPolicy = () => {
  const {
    treetShopName,
    copy: { faqCopy },
    internationalConfig,
  } = useShopConfig();
  const { allowSell } = useEnabledCustomerExperiences();
  const { shouldOfferReturnInsurance } = useReturnInsurance();

  const isUKOrEUShop = isUkOrEUShop(internationalConfig);

  const treetProtectionText = !allowSell
    ? `All ${treetShopName} orders are final sale; however, all orders`
    : `All other orders made through ${treetShopName}`;

  return (
    <>
      <StyledAccordion name="return-refund-policy">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>
            <FAQTitle>{faqCopy[FAQ.Refund]?.title || 'What’s the refund/return policy?'}</FAQTitle>
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          {faqCopy[FAQ.Refund] ? (
            <span>
              <h4>
                <FAQDescription>{faqCopy[FAQ.Refund].description}</FAQDescription>
              </h4>
            </span>
          ) : (
            <div>
              {isUKOrEUShop && (
                <h4>
                  <TypographyWrapper variant="body1">
                    All Brand Direct items have a 14 day return policy.
                  </TypographyWrapper>
                </h4>
              )}
              <h4>
                <TypographyWrapper variant="body1">
                  {treetProtectionText} are backed by{' '}
                  <NamedLink
                    name="AboutPage"
                    params={{ tab: AboutPageTab.Protection }}
                    className={css.link}
                  >
                    <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                      Treet Protection
                    </TypographyWrapper>
                  </NamedLink>
                  : get the item that was promised in the condition it was promised in, or receive a
                  full refund.
                </TypographyWrapper>
              </h4>
              <h4>
                <TypographyWrapper variant="body1">
                  In order to receive a refund for your order, you must file a Misrepresentation
                  Claim within 72 hours of receiving your order.{' '}
                  <NamedLink
                    name="AboutPage"
                    params={{ tab: AboutPageTab.Protection }}
                    className={css.link}
                  >
                    <TypographyWrapper
                      variant="body1"
                      typographyOverrides={{
                        display: 'inline',
                      }}
                    >
                      Here
                    </TypographyWrapper>
                  </NamedLink>{' '}
                  are the steps to do so.
                </TypographyWrapper>
              </h4>
              {allowSell && (
                <h4>
                  <TypographyWrapper variant="body1">
                    If for whatever reason you decide you don’t like your item, this is not covered
                    in Treet Protection, but you still can re-list the product without paying any
                    Seller fees. In this case, you must request to re-list the item within 72 hours
                    of its arrival by contacting support@treet.co.
                  </TypographyWrapper>
                </h4>
              )}
              {shouldOfferReturnInsurance && (
                <h4>
                  <TypographyWrapper variant="body1">
                    You can also choose to add Seel {SEEL_RETURN_ASSURANCE} to your order at
                    checkout if your order is eligible. {SEEL_RETURN_ASSURANCE} gives you a 7-day
                    return window on your otherwise final sale (non-refundable) items. If you’re
                    unhappy with the purchase for any reason, Seel will buy it back from you for
                    100% of the purchase price of your returned items. Please note that shipping
                    fees are not covered by {SEEL_RETURN_ASSURANCE}. The original shipping fee will
                    not be returned and a $10 return shipping fee will be deducted from your final
                    refund.
                  </TypographyWrapper>
                </h4>
              )}
            </div>
          )}
        </AccordionDetails>
      </StyledAccordion>
    </>
  );
};

const BuyerAdditional: FC = () => {
  const {
    copy: { buyerAdditionalFaq },
  } = useShopConfig();

  const buildFaqNameForAnchorLink = (title: string, index: number) =>
    `${title || `buyer-additional-${index}`}`;

  return (
    <>
      {buyerAdditionalFaq.map((faq: FAQObject, index: number) => (
        <StyledAccordion
          key={buildFaqNameForAnchorLink(faq.title, index)}
          name={buildFaqNameForAnchorLink(faq.title, index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
          >
            <p>
              <FAQTitle>{faq.title}</FAQTitle>
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <span>
              <p>
                <FAQDescription>{faq.description}</FAQDescription>
              </p>
            </span>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </>
  );
};

const BuyerFAQ: FC = () => {
  // TODO (SY|TREET-1066): Once all fields are moved to
  // contentful, refactor this to use shopconfigV2
  const {
    treetShopName,
    copy: { buyerAdditionalFaq, faqCopy },
  } = useShopConfig();
  const { allowSell } = useEnabledCustomerExperiences();
  const { countryCode: userCountryCode, canShipWithinUserCountry } = useUserCountryConfig();
  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <>
      <StyledAccordion name="why-shop">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h4>
            <FAQTitle>{faqCopy[FAQ.WhyShop]?.title || 'Why should I shop here?'}</FAQTitle>
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <span>
            {faqCopy[FAQ.WhyShop] ? (
              <h4>
                <FAQDescription>{faqCopy[FAQ.WhyShop].description}</FAQDescription>
              </h4>
            ) : (
              <>
                <h4>
                  <TypographyWrapper variant="body1">
                    {treetShopName} is the dedicated site for buying and selling our products
                    secondhand. We’ve created this space for you to have the best shopping
                    experience for finding and purchasing our secondhand items.
                  </TypographyWrapper>
                </h4>
                <h4>
                  <TypographyWrapper variant="body1">
                    All posts are reviewed prior to going live and your purchases are backed by
                    Treet Protection so you’re guaranteed to receive the product that was promised.
                  </TypographyWrapper>
                </h4>
              </>
            )}
          </span>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion name="shipping-deadline">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h4>
            <FAQTitle>How long will it take for my order to arrive?</FAQTitle>
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <span>
            <h4>
              <TypographyWrapper variant="body1">
                {!allowSell ? (
                  <>
                    After completing your order, your item can take up to {ShipDeadline.Default}{' '}
                    business days to ship. Please note that shipping is paid for by the Buyer.
                  </>
                ) : (
                  <>
                    After completing your order, an email is sent to the Seller with a prepaid
                    shipping label and they’re encouraged to ship immediately. It usually takes
                    around 3 days for Sellers to get items in the mail, but it can take up to{' '}
                    {ShipDeadline.Default} business days. Please note that shipping is paid for by
                    the Buyer.
                  </>
                )}
              </TypographyWrapper>
            </h4>
            {userCountryCode === CountryCode.Us && canShipWithinUserCountry && (
              <h4>
                <TypographyWrapper variant="body1">
                  {`Shipments over ${formatDollarAmount(
                    intl,
                    50000,
                    currencyConfig
                  )} will require a signature confirmation upon delivery.`}
                </TypographyWrapper>
              </h4>
            )}
          </span>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion name="missed-shipping-deadline">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <h4>
            <FAQTitle>
              {`What happens if my item doesn’t ship after ${ShipDeadline.Default} business days?`}
            </FAQTitle>
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <h4>
            <TypographyWrapper variant="body1">
              <>
                On the rare occasion where your order doesn’t ship within the {ShipDeadline.Default}
                -day shipping window, we will automatically cancel the order and refund your
                purchase.
              </>
            </TypographyWrapper>
          </h4>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion name="payment-methods">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <h4>
            <FAQTitle>
              {faqCopy[FAQ.Payment]?.title || 'What payment methods are accepted?'}
            </FAQTitle>
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <h4>
            <TypographyWrapper variant="body1">
              <>
                {faqCopy[FAQ.Payment]?.description ||
                  'All major credit and debit cards are accepted for payment, including: American Express, Visa, MasterCard, and Discover.'}
              </>
            </TypographyWrapper>
          </h4>
        </AccordionDetails>
      </StyledAccordion>
      <RefundPolicy />
      {buyerAdditionalFaq && <BuyerAdditional />}
    </>
  );
};
export default BuyerFAQ;
