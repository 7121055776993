/* eslint-disable import/prefer-default-export */
import { ListingWithImages, OwnListingWithImages } from '../types/sharetribe/listing';
import {
  GetFeaturedImageInputs,
  getFeaturedListingImageHelper,
  getSharetribeImagesForListing,
  getStockImagesForListing,
  getUploadcareImagesForListing,
} from '../util/listings/listingImages';
import { shouldRenderBackgroundBlurFn } from '../containers/ListingPage/SectionImages.utils';
import { useShopConfig } from './shopConfig';

type ExtendedGetFeaturedImageInputs = GetFeaturedImageInputs & {
  imageIndex: number;
};

// Keep core logic in sync with server/utils/listings.ts
export const getFeaturedListingImage = (params: GetFeaturedImageInputs) => {
  const {
    firstStockImageSrc,
    firstSellerImage,
    firstSellerImageSrc,
    shouldShowStockImage,
    isUploadcareEnabled,
  } = getFeaturedListingImageHelper(params);

  const { listing } = params;

  const { images } = listing?.attributes?.publicData;

  if (shouldShowStockImage && firstStockImageSrc) {
    const shouldRenderBackgroundBlur = shouldRenderBackgroundBlurFn(firstStockImageSrc, images);
    return { src: firstStockImageSrc, shouldRenderBackgroundBlur };
  }

  if (isUploadcareEnabled && firstSellerImageSrc) {
    const shouldRenderBackgroundBlur = shouldRenderBackgroundBlurFn(firstSellerImageSrc, images);
    return {
      src: firstSellerImageSrc,
      shouldRenderBackgroundBlur,
    };
  }

  return { image: firstSellerImage };
};

/**
 * Returns the featured image for the listing
 */
export const useFeaturedListingImage = (
  listing: ListingWithImages | OwnListingWithImages,
  forceHideStockImages?: boolean
) => {
  const {
    chooseStockImagesOption,
    maxStockImages,
    listingFlowConfig: { featuredListingImageType },
    imageRatio,
  } = useShopConfig();

  return getFeaturedListingImage({
    showStockImages: !forceHideStockImages && maxStockImages > 0,
    featuredListingImageType,
    chooseStockImagesOption,
    listing,
    imageRatio,
  });
};

// TODO: (HM |TREET-6824) refactor getSpecificListingImage and getFeaturedListingImage
/*
  returns a specific image from the ordered list of images for a listing,
  taking into account the featured image rules
*/
export const getSpecificListingImage = (params: ExtendedGetFeaturedImageInputs) => {
  if (!params?.listing) return null;
  const { chooseStockImagesOption, listing, imageIndex } = params;
  const { shouldShowStockImage, isUploadcareEnabled } = getFeaturedListingImageHelper(params);

  const { images } = listing?.attributes?.publicData;

  if (shouldShowStockImage) {
    const listingStockImages = getStockImagesForListing(listing, chooseStockImagesOption) || [];
    const stockImageSrc = listingStockImages?.[imageIndex];
    if (stockImageSrc) {
      const shouldRenderBackgroundBlur = shouldRenderBackgroundBlurFn(stockImageSrc, images);
      return { src: stockImageSrc, shouldRenderBackgroundBlur };
    }
  }
  if (isUploadcareEnabled) {
    const uploadcareImages = getUploadcareImagesForListing(listing);
    const sellerImageSrc = uploadcareImages?.[imageIndex];
    const shouldRenderBackgroundBlur = shouldRenderBackgroundBlurFn(sellerImageSrc, images);

    if (sellerImageSrc) {
      return {
        src: sellerImageSrc,
        shouldRenderBackgroundBlur,
      };
    }
  }
  const sharetribeImages = getSharetribeImagesForListing(listing);
  const sharetribeImage = sharetribeImages?.[imageIndex];
  return sharetribeImage;
};

// TODO: (HM |TREET-6824) refactor useSpecificListingImage and useFeaturedListingImage
export const useSpecificListingImage = (
  listing: ListingWithImages | OwnListingWithImages,
  imageIndex: number,
  forceHideStockImages?: boolean
) => {
  const {
    chooseStockImagesOption,
    maxStockImages,
    listingFlowConfig: { featuredListingImageType },
    imageRatio,
  } = useShopConfig();

  return getSpecificListingImage({
    showStockImages: !forceHideStockImages && maxStockImages > 0 && maxStockImages > imageIndex,
    featuredListingImageType,
    chooseStockImagesOption,
    listing,
    imageRatio,
    imageIndex,
  });
};
