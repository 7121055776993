import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { PrivateShopConfig, ShopConfigV2 } from '../types/shopConfig/shopConfigV2';
import {
  aceandjigShopConfig,
  allegrajamesShopConfig,
  allwearShopConfig,
  alpakagearConfig,
  altarpdxShopConfig,
  alysoneastmanShopConfig,
  anianShopConfig,
  asrvShopConfig,
  ayblShopConfig,
  baabukShopConfig,
  bandierShopConfig,
  betterlifebagsShopConfig,
  birdygreyShopConfig,
  bohemianmamaShopConfig,
  boyishShopConfig,
  briarbabyShopConfig,
  brooklynclothShopConfig,
  bryrShopConfig,
  burjushoesShopConfig,
  callahanShopConfig,
  carilohaShopConfig,
  charlottestoneShopConfig,
  cinnamonbabyauShopConfig,
  coclicoShopConfig,
  costumeboxShopConfig,
  cotopaxiShopConfig,
  cozyearthShopConfig,
  creocardsShopConfig,
  crosbybymollieburchShopConfig,
  crownforeverlaShopConfig,
  curatorsfShopConfig,
  cutsShopConfig,
  danielPatrickShopConfig,
  dixxonShopConfig,
  dl1961ShopConfig,
  doenShopConfig,
  dollskillShopConfig,
  dreamlandbabycoShopConfig,
  electricandroseShopConfig,
  electricyogaShopConfig,
  elevatedfaithShopConfig,
  elfinlosangelesShopConfig,
  elizabethsuzannShopConfig,
  encircledCaShopConfig,
  encircledUsShopConfig,
  epoqueevolutionShopConfig,
  esemblybabyShopConfig,
  ettitudeShopConfig,
  exoticathleticaShopConfig,
  firebirdShopConfig,
  forloveandlemonsShopConfig,
  fortressShopConfig,
  freedomravewearShopConfig,
  galialahavShopConfig,
  gerganaivanovaShopConfig,
  girlcrushShopConfig,
  girlfriendShopConfig,
  goldieandaceShopConfig,
  goldieteesShopConfig,
  goodlifeShopConfig,
  gravelandgoldShopConfig,
  grayersShopConfig,
  heathCeramicsShopConfig,
  helmShopConfig,
  henkaaShopConfig,
  hernestprojectShopConfig,
  holdenouterwearSizesShopConfig,
  hopeandplumShopConfig,
  hudsonjeansShopConfig,
  huxbabyShopConfig,
  hyergoodsShopConfig,
  iksplorShopConfig,
  industryofallnationsShopConfig,
  ingridandisabelShopConfig,
  ironandresinShopConfig,
  itsmillaShopConfig,
  jamesstreetcoShopConfig,
  jennylemonsShopConfig,
  jillianboustredShopConfig,
  joahloveShopConfig,
  johnandmickieShopConfig,
  jordancraigShopConfig,
  joyfolieShopConfig,
  jujubeShopConfig,
  karinadressesShopConfig,
  kickeepantsShopConfig,
  kirrinfinchShopConfig,
  kitsbowShopConfig,
  kittenyShopConfig,
  koralShopConfig,
  kpopexchangeShopConfig,
  kyrgiesShopConfig,
  lafemmeaperoShopConfig,
  laudethelabelShopConfig,
  littleandlivelyShopConfig,
  livetheprocessShopConfig,
  livsnShopConfig,
  lornajaneShopConfig,
  loveandgrowclothingShopConfig,
  lovedbabyShopConfig,
  lunapacaShopConfig,
  lykkewullfShopConfig,
  maajiShopConfig,
  maboclothierShopConfig,
  mainstreetexchangeapparelShopConfig,
  miakodaShopConfig,
  mignonnegaviganShopConfig,
  mikimietteShopConfig,
  minimiocheShopConfig,
  ministryofsupplyShopConfig,
  minnowShopConfig,
  moderncitizenShopConfig,
  moskomotoShopConfig,
  mpatmosShopConfig,
  mpgsportcanadaShopConfig,
  mpgsportusaShopConfig,
  musethreadsShopConfig,
  mylesShopConfig,
  mymummadeitShopConfig,
  namaiShopConfig,
  nettlestudiosShopConfig,
  neunomadsShopConfig,
  noguShopConfig,
  nooworksShopConfig,
  nuiorganicsShopConfig,
  opheliaandindigoShopConfig,
  oschaslingsUkShopConfig,
  oschaslingsUsShopConfig,
  osoandmeShopConfig,
  paktShopConfig,
  parkerclayShopConfig,
  peachyDogsShopConfig,
  pfflyersShopConfig,
  pistollakeShopConfig,
  plaeShopConfig,
  polejunkieShopConfig,
  pontoShopConfig,
  poplinenShopConfig,
  portlandleatherShopConfig,
  princesspollyShopConfig,
  prismboutiqueShopConfig,
  proudpoppyclothingShopConfig,
  quinceandcoShopConfig,
  quincymaeShopConfig,
  rachelantonoffShopConfig,
  ranchroadShopConfig,
  ratboiShopConfig,
  raveivalShopConfig,
  redoppleShopConfig,
  relightCommunityMarketplaceShopConfig,
  relovedShopConfig,
  representShopConfig,
  robertgrahamShopConfig,
  rollasjeansAuShopConfig,
  rollasjeansUsShopConfig,
  romanandrileyShopConfig,
  rooleeShopConfig,
  roughandtumbleShopConfig,
  ryleeandcruShopConfig,
  saguaroshowpadsShopConfig,
  sarahwellsbagsShopConfig,
  saylorShopConfig,
  seaestasurfShopConfig,
  seniqShopConfig,
  senitaathleticsShopConfig,
  sforceShopConfig,
  shopdyiShopConfig,
  shopeitherorShopConfig,
  shopfavoritedaughterShopConfig,
  sijoShopConfig,
  simonsShopConfig,
  skhoopShopConfig,
  southernTatersShopConfig,
  southerntideShopConfig,
  sozyShopConfig,
  spellShopConfig,
  spellusaShopConfig,
  standardandstrangeShopConfig,
  statebagsShopConfig,
  statethelabelShopConfig,
  superxShopConfig,
  surlacoteShopConfig,
  swoverallsShopConfig,
  tallsizeCaShopConfig,
  tallsizeShopConfig,
  talulahShopConfig,
  tecovasShopConfig,
  teddyblakeShopConfig,
  teekiboutiqueShopConfig,
  tenable6ShopConfig,
  tentreeCaShopConfig,
  tentreeShopConfig,
  texasstandardShopConfig,
  theandibrandShopConfig,
  thebeaufortbonnetcompanyShopConfig,
  thebeeandthefoxShopConfig,
  theheygangShopConfig,
  thelullabyclubShopConfig,
  thenutrShopConfig,
  theslowwardrobeUkShopConfig,
  theslowwardrobeUsShopConfig,
  timininousShopConfig,
  tradlandsShopConfig,
  treetfontsShopConfig,
  treetonShopConfig,
  tuesdayofcaliforniaShopConfig,
  unhideShopConfig,
  unifShopConfig,
  unionbayShopConfig,
  urbancredShopConfig,
  velvetyShopConfig,
  vettaShopConfig,
  viscataShopConfig,
  vitaeapparelShopConfig,
  vitalydesignShopConfig,
  vivobarefootcaShopConfig,
  vplShopConfig,
  wayreShopConfig,
  weisswatchcompanyShopConfig,
  westernandcoShopConfig,
  whimsyandrowShopConfig,
  whyandwhaleShopConfig,
  wildryeShopConfig,
  wolvenShopConfig,
  woodleyloweShopConfig,
  wornhubShopConfig,
  wrayShopConfig,
  youerShopConfig,
  ziabirdShopConfig,
} from './shops';
import { getCategoryFilterConfig } from './filters/styles';
import { ShopConfigResponse } from '../types/contentful/shopConfig';
import { FilterId } from '../types/filters/filters';

type PrivateShopConfigs = {
  [treetId: string]: PrivateShopConfig;
};

const privateShopConfigs: PrivateShopConfigs = {
  aceandjig: aceandjigShopConfig,
  anian: anianShopConfig,
  aybl: ayblShopConfig,
  allegrajames: allegrajamesShopConfig,
  alpakagear: alpakagearConfig,
  allwear: allwearShopConfig,
  altarpdx: altarpdxShopConfig,
  alysoneastman: alysoneastmanShopConfig,
  asrv: asrvShopConfig,
  baabuk: baabukShopConfig,
  bandier: bandierShopConfig,
  proudpoppyclothing: proudpoppyclothingShopConfig,
  birdygrey: birdygreyShopConfig,
  betterlifebags: betterlifebagsShopConfig,
  bohemianmama: bohemianmamaShopConfig,
  boyish: boyishShopConfig,
  briarbaby: briarbabyShopConfig,
  brooklyncloth: brooklynclothShopConfig,
  bryr: bryrShopConfig,
  burjushoes: burjushoesShopConfig,
  callahan: callahanShopConfig,
  cariloha: carilohaShopConfig,
  'charlotte-stone': charlottestoneShopConfig,
  cinnamonbabyau: cinnamonbabyauShopConfig,
  coclico: coclicoShopConfig,
  costumebox: costumeboxShopConfig,
  cotopaxi: cotopaxiShopConfig,
  cozyearth: cozyearthShopConfig,
  creocards: creocardsShopConfig,
  crosbybymollieburch: crosbybymollieburchShopConfig,
  crownforeverla: crownforeverlaShopConfig,
  curatorsf: curatorsfShopConfig,
  cuts: cutsShopConfig,
  'daniel-patrick': danielPatrickShopConfig,
  dixxon: dixxonShopConfig,
  dl1961: dl1961ShopConfig,
  doen: doenShopConfig,
  dollskill: dollskillShopConfig,
  dreamlandbabyco: dreamlandbabycoShopConfig,
  elevatedfaith: elevatedfaithShopConfig,
  electricandrose: electricandroseShopConfig,
  electricyoga: electricyogaShopConfig,
  elfinlosangeles: elfinlosangelesShopConfig,
  elizabethsuzann: elizabethsuzannShopConfig,
  'encircled-ca': encircledCaShopConfig,
  'encircled-us': encircledUsShopConfig,
  'epoque-evolution': epoqueevolutionShopConfig,
  esemblybaby: esemblybabyShopConfig,
  ettitude: ettitudeShopConfig,
  exoticathletica: exoticathleticaShopConfig,
  firebird: firebirdShopConfig,
  forloveandlemons: forloveandlemonsShopConfig,
  fortress: fortressShopConfig,
  freedomravewear: freedomravewearShopConfig,
  galialahav: galialahavShopConfig,
  gerganaivanova: gerganaivanovaShopConfig,
  girlcrush: girlcrushShopConfig,
  girlfriend: girlfriendShopConfig,
  goldieandace: goldieandaceShopConfig,
  goldietees: goldieteesShopConfig,
  goodlife: goodlifeShopConfig,
  gravelandgold: gravelandgoldShopConfig,
  grayers: grayersShopConfig,
  helm: helmShopConfig,
  henkaa: henkaaShopConfig,
  hernestproject: hernestprojectShopConfig,
  holdenouterwear: holdenouterwearSizesShopConfig,
  hopeandplum: hopeandplumShopConfig,
  hudsonjeans: hudsonjeansShopConfig,
  huxbaby: huxbabyShopConfig,
  hyergoods: hyergoodsShopConfig,
  iksplor: iksplorShopConfig,
  industryofallnations: industryofallnationsShopConfig,
  ingridandisabel: ingridandisabelShopConfig,
  ironandresin: ironandresinShopConfig,
  itsmilla: itsmillaShopConfig,
  jamesstreetco: jamesstreetcoShopConfig,
  jennylemons: jennylemonsShopConfig,
  jillianboustred: jillianboustredShopConfig,
  joahlove: joahloveShopConfig,
  johnandmickie: johnandmickieShopConfig,
  jordancraig: jordancraigShopConfig,
  joyfolie: joyfolieShopConfig,
  jujube: jujubeShopConfig,
  karinadresses: karinadressesShopConfig,
  kickeepants: kickeepantsShopConfig,
  kirrinfinch: kirrinfinchShopConfig,
  kitsbow: kitsbowShopConfig,
  kitteny: kittenyShopConfig,
  koral: koralShopConfig,
  kpopexchange: kpopexchangeShopConfig,
  kyrgies: kyrgiesShopConfig,
  lafemmeapero: lafemmeaperoShopConfig,
  laudethelabel: laudethelabelShopConfig,
  littleandlively: littleandlivelyShopConfig,
  livetheprocess: livetheprocessShopConfig,
  livsn: livsnShopConfig,
  lornajane: lornajaneShopConfig,
  loveandgrowclothing: loveandgrowclothingShopConfig,
  lovedbaby: lovedbabyShopConfig,
  lunapaca: lunapacaShopConfig,
  lykkewullf: lykkewullfShopConfig,
  marketplace: cutsShopConfig,
  maaji: maajiShopConfig,
  maboclothier: maboclothierShopConfig,
  mainstreetexchangeapparel: mainstreetexchangeapparelShopConfig,
  miakoda: miakodaShopConfig,
  mignonnegavigan: mignonnegaviganShopConfig,
  mikimiette: mikimietteShopConfig,
  minimioche: minimiocheShopConfig,
  ministryofsupply: ministryofsupplyShopConfig,
  minnow: minnowShopConfig,
  moderncitizen: moderncitizenShopConfig,
  moskomoto: moskomotoShopConfig,
  mpgsportcanada: mpgsportcanadaShopConfig,
  mpgsportusa: mpgsportusaShopConfig,
  mpatmos: mpatmosShopConfig,
  musethreads: musethreadsShopConfig,
  'mymum-madeit': mymummadeitShopConfig,
  myles: mylesShopConfig,
  namai: namaiShopConfig,
  nettlestudios: nettlestudiosShopConfig,
  neunomads: neunomadsShopConfig,
  nooworks: nooworksShopConfig,
  nogu: noguShopConfig,
  nuiorganics: nuiorganicsShopConfig,
  'oschaslings-uk': oschaslingsUkShopConfig,
  'oschaslings-us': oschaslingsUsShopConfig,
  osoandme: osoandmeShopConfig,
  opheliaandindigo: opheliaandindigoShopConfig,
  pakt: paktShopConfig,
  parkerclay: parkerclayShopConfig,
  peachydogs: peachyDogsShopConfig,
  pfflyers: pfflyersShopConfig,
  pistollake: pistollakeShopConfig,
  plae: plaeShopConfig,
  polejunkie: polejunkieShopConfig,
  ponto: pontoShopConfig,
  poplinen: poplinenShopConfig,
  portlandleather: portlandleatherShopConfig,
  princesspolly: princesspollyShopConfig,
  prismboutique: prismboutiqueShopConfig,
  quinceandco: quinceandcoShopConfig,
  quincymae: quincymaeShopConfig,
  rachelantonoff: rachelantonoffShopConfig,
  ranchroad: ranchroadShopConfig,
  ratboi: ratboiShopConfig,
  raveival: raveivalShopConfig,
  redopple: redoppleShopConfig,
  relightcommunitymarketplace: relightCommunityMarketplaceShopConfig,
  reloved: relovedShopConfig,
  represent: representShopConfig,
  robertgraham: robertgrahamShopConfig,
  'rollasjeans-au': rollasjeansAuShopConfig,
  'rollasjeans-us': rollasjeansUsShopConfig,
  romanandriley: romanandrileyShopConfig,
  roolee: rooleeShopConfig,
  roughandtumble: roughandtumbleShopConfig,
  ryleeandcru: ryleeandcruShopConfig,
  saguaroshowpads: saguaroshowpadsShopConfig,
  sarahwellsbags: sarahwellsbagsShopConfig,
  saylor: saylorShopConfig,
  seaestasurf: seaestasurfShopConfig,
  seniq: seniqShopConfig,
  senitaathletics: senitaathleticsShopConfig,
  sforce: sforceShopConfig,
  shopdyi: shopdyiShopConfig,
  shopeitheror: shopeitherorShopConfig,
  shopfavoritedaughter: shopfavoritedaughterShopConfig,
  sijo: sijoShopConfig,
  simons: simonsShopConfig,
  skhoop: skhoopShopConfig,
  sozy: sozyShopConfig,
  spell: spellShopConfig,
  'spell-usa': spellusaShopConfig,
  standardandstrange: standardandstrangeShopConfig,
  statebags: statebagsShopConfig,
  statethelabel: statethelabelShopConfig,
  superx: superxShopConfig,
  surlacote: surlacoteShopConfig,
  southerntide: southerntideShopConfig,
  southerntaters: southernTatersShopConfig,
  swoveralls: swoverallsShopConfig,
  tallsize: tallsizeShopConfig,
  'tallsize-ca': tallsizeCaShopConfig,
  talulah: talulahShopConfig,
  tecovas: tecovasShopConfig,
  teddyblake: teddyblakeShopConfig,
  teekiboutique: teekiboutiqueShopConfig,
  tenable6: tenable6ShopConfig,
  tentree: tentreeShopConfig,
  'tentree-ca': tentreeCaShopConfig,
  'texas-standard': texasstandardShopConfig,
  tradlands: tradlandsShopConfig,
  treetfonts: treetfontsShopConfig,
  theandibrand: theandibrandShopConfig,
  thebeaufortbonnetcompany: thebeaufortbonnetcompanyShopConfig,
  thebeeandthefox: thebeeandthefoxShopConfig,
  theheygang: theheygangShopConfig,
  thelullabyclub: thelullabyclubShopConfig,
  thenutr: thenutrShopConfig,
  'theslowwardrobe-us': theslowwardrobeUsShopConfig,
  'theslowwardrobe-uk': theslowwardrobeUkShopConfig,
  timininous: timininousShopConfig,
  treeton: treetonShopConfig,
  tuesdayofcalifornia: tuesdayofcaliforniaShopConfig,
  unhide: unhideShopConfig,
  unif: unifShopConfig,
  unionbay: unionbayShopConfig,
  urbancred: urbancredShopConfig,
  velvety: velvetyShopConfig,
  vetta: vettaShopConfig,
  viscata: viscataShopConfig,
  vitaeapparel: vitaeapparelShopConfig,
  vitalydesign: vitalydesignShopConfig,
  'vivobarefoot-ca': vivobarefootcaShopConfig,
  vpl: vplShopConfig,
  wayre: wayreShopConfig,
  weisswatchcompany: weisswatchcompanyShopConfig,
  westernandco: westernandcoShopConfig,
  whimsyandrow: whimsyandrowShopConfig,
  wildrye: wildryeShopConfig,
  wolven: wolvenShopConfig,
  woodleylowe: woodleyloweShopConfig,
  wornhub: wornhubShopConfig,
  whyandwhale: whyandwhaleShopConfig,
  wray: wrayShopConfig,
  youer: youerShopConfig,
  ziabird: ziabirdShopConfig,
  heathceramics: heathCeramicsShopConfig,
};

// Create a ShopConfigV2 by combining the shop config from Contentful and the one in code
export const buildShopConfig = (contentfulShopConfig: ShopConfigResponse): ShopConfigV2 => {
  const { categoriesConfig, shopId } = contentfulShopConfig;
  const privateShopConfig = privateShopConfigs[shopId];
  return {
    ...contentfulShopConfig,
    ...privateShopConfig,
    ...(categoriesConfig && {
      filters: [
        ...privateShopConfig.filters,
        getCategoryFilterConfig({
          id: categoriesConfig.filterId as FilterId | undefined,
          shouldUseTreetOptions: categoriesConfig.useTreetOptions,
          options: categoriesConfig.categoriesCollection?.items || [],
        }),
      ],
    }),
  };
};

export const getPrivateShopConfig = (treetId: string) => privateShopConfigs[treetId];

/**
 * Translates a V2 version of the shop config (from contentful) to a V1 version
 * This is used to bridge the gap between V1 & V2. Eventually, everything should
 * be accessed directly from V2.
 * @param {shopConfigV2} shopConfigV2
 * @returns V1 version of the shopconfig
 */
export const combineV1AndV2Config = (shopConfigV1: any, shopConfigV2: ShopConfigV2) => {
  // Remove fields that are undefined/null
  const cleanShopConfigV2 = omitBy(shopConfigV2, isNil);

  return {
    ...shopConfigV1,
    ...cleanShopConfigV2,
  };
};
