import { ListingFlowSectionToTimestamp } from '../../containers/EditListingPage/EditListingPage.utils';
import { Conditions } from '../../shopConfig/filters/condition';
import { PayoutOptions } from '../../util/constants';
import { FindItemMethod } from '../../util/listings/listing';
import { ImageSource } from '../../util/uploadcare';
import { CountryCode } from '../apollo/generated/types.generated';
import { IShopCategory } from '../contentful/types.generated';
import { Product, ShopifyProductVariant } from '../shopify/product';
import { SharetribeAddress } from './address';
import { Money } from './money';
import { User } from './user';
import { Uuid } from './uuid';

export type ListingWithAuthorAndImages = ListingWithImages & ListingWithAuthor;

export type ListingWithAuthor = Listing & {
  author: User;
};

export type ListingWithImages = Listing & {
  images: SharetribeImage[];
};

type ListingAttributes = {
  title: string;
  description: string;
  geolocation: any | null;
  createdAt: Date;
  price: Money;
  availabilityPlan: any;
  deleted: boolean;
  state: string;
  publicData?: any;
  metadata?: any;
};

type ListingAttributesWithShopifyProductVariant = ListingAttributes & {
  publicData: {
    shopifyProductVariant: ShopifyProductVariant;
  };
};

export type Listing = {
  id: Uuid;
  type: 'listing';
  attributes: ListingAttributes;
};

export type OwnListing = {
  id: Uuid;
  type: 'ownListing';
  attributes: ListingAttributes & {
    privateData?: any;
  };
};

export type OwnListingWithImages = OwnListing & {
  images: SharetribeImage[];
};

export type OwnListingWithImagesAndShopifyProductVariant = OwnListingWithImages & {
  attributes: ListingAttributesWithShopifyProductVariant;
};

export enum RecommendedListingsType {
  Related = 'RELATED',
  Popular = 'POPULAR',
}

export type SharetribeImage = {
  id?: Uuid;
  type: 'image';
  attributes: {
    variants: {
      [k: string]: {
        name: string;
        width: number;
        height: number;
        url: string;
      };
    };
  };
};

export enum ListingItemType {
  TradeIn = 'TRADE_IN',
  Marketplace = 'MARKETPLACE',
}

export type SharetribeListingBasicFieldsPayload = {
  id?: string;
  shopName?: string;
  listingId?: string;
  state?: string;
  price?: Money;
};

export type SharetribeListingPrivateDataPayload = {
  shipFromAddress?: SharetribeAddress;
  payoutOption?: PayoutOptions | null;
};

// This type is being used to support both draft listings and published listings, so there are many fields that are optional
// that are actually required to create a listing, ie. condition
// Keep in sync with BE type in server/types/sharetribe/listing.ts
export type SharetribeListingPublicDataPayload = {
  approvedAt?: number;
  approvalEmailSent?: boolean;
  automaticPriceDropInfo?: {
    anchorPrice?: number | null;
    enabled?: boolean | null;
    minPrice?: number | null;
    percentageDrop?: number | null;
  };
  availability: string;
  brandApprovalFields?: string[];
  categories: string[] | null;
  category: string | null;
  condition?: Conditions;
  conditionInfo?: {
    condition?: Conditions;
    notes?: string;
    quirks?: string[];
  };
  configCategories?: IShopCategory[];
  createdListingId?: string;
  didUseQRCode?: string;
  disableAutoApproval: boolean;
  domain: string;
  emailOrPhoneForOrder?: string;
  featuredImageId?: string;
  findItemMethod: FindItemMethod;
  images?: any[] | null;
  imageSource?: ImageSource;
  internalBrandNotes?: string;
  isBrandDirect: boolean;
  lineItemId?: string | null;
  listingStartTime?: number;
  listingFinishTime?: number;
  listingFlowId?: string;
  listingFlowSectionToTimestamp?: ListingFlowSectionToTimestamp | null;
  listingItemType?: ListingItemType;
  numRequiredImages: number;
  orderConfirmationNumber?: string | null;
  originalPrice?: number | null;
  orderedImageIds?: string[];
  originatedAsGuestListing: boolean;
  payoutCreditBoostPromoId?: string;
  payoutInfo: {
    cashPayoutPercentage: number;
    creditPayoutPercentage: number;
  };
  price: Money;
  productTitleDisplayType?: string;
  recommendedPrice?: number | null;
  selectedVariantOptions?: Record<string, string> | null;
  shipFromCountry: CountryCode;
  shopifyProduct?: Product | null;
  shopifyProductId?: string;
  shopifyProductVariant?: ShopifyProductVariant | null;
  shopifyProductVariantId?: string;
  shopifyProductVariantSku?: string;
  shopName: string;
  stockPhotoUrls?: string[] | null;
  sourceListingId?: string;
  syncShopifyInventory?: any;
  tags?: string[] | null;
  titleOverride?: string;
  updatedAt: number;
  userGeneratedBrandName?: string;
  userGeneratedTitle?: string;
};
