import { Box, Tooltip, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { flatten, isEmpty, isNil } from 'lodash';
import { Divider, IconInfo, TypographyWrapper } from '..';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { defaultTreetStyles } from '../../shopConfig/config';
import {
  BundleType,
  CoreRefund,
  LineItemCode,
  Participant,
} from '../../types/apollo/generated/types.generated';
import { CheckoutLineItem } from '../../types/models/lineItem';
import {
  calculateLineItemTotal,
  getDiscountTotal,
  getListingsTotal,
  getReturnInsuranceTotal,
  getShippingTotal,
  getTaxTotal,
  SIGNATURE_CONFIRMATION_SHIPPING_LINE_ITEM_NAME,
} from '../../util/lineItems';
import { formatDollarAmount } from '../../util/currency';
import { useTax } from '../../hooks/useTax';
import { useShopConfig } from '../../hooks/shopConfig';
import DynamicValueWrapper from '../DynamicValueWrapper/DynamicValueWrapper';

const LineItemWrapper: FC = (props) => (
  <Box display="flex" flexDirection="row" justifyContent="space-between" p={2}>
    {props.children}
  </Box>
);

interface ItemsAndShippingLineItemProps {
  hasShippingLineItems: boolean;
  shippingTotal: number;
  itemsAndShippingTotal: number;
}

const ItemsAndShippingLineItem: FC<ItemsAndShippingLineItemProps> = (props) => {
  const { hasShippingLineItems, shippingTotal, itemsAndShippingTotal } = props;

  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <>
      <LineItemWrapper>
        <Typography variant="body2" style={{ fontWeight: 'bold' }} display="inline">
          {shippingTotal > 0 ? 'Items + Shipping' : 'Items'}
        </Typography>
        <Typography variant="body2" style={{ textAlign: 'end' }}>
          {hasShippingLineItems ? (
            <DynamicValueWrapper>
              {intl.formatNumber(itemsAndShippingTotal, currencyConfig)}
            </DynamicValueWrapper>
          ) : (
            'Calculated based on shipping address'
          )}
        </Typography>
      </LineItemWrapper>
      <Divider />
    </>
  );
};

interface ItemsLineItemProps {
  listingsTotal: number;
}

const ItemsLineItem: FC<ItemsLineItemProps> = ({ listingsTotal }) => {
  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <LineItemWrapper>
      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
        Items
      </Typography>
      <Typography variant="body2">{intl.formatNumber(listingsTotal, currencyConfig)}</Typography>
    </LineItemWrapper>
  );
};

interface ShippingLineItemProps {
  lineItems: CheckoutLineItem[];
}

interface ShippingToolTipProps {
  text: string;
}

const ShippingToolTip: FC<ShippingToolTipProps> = ({ text }) => (
  <Box ml={1}>
    <Tooltip
      title={
        <TypographyWrapper variant="body2" typographyOverrides={{ color: 'inherit' }}>
          {text}
        </TypographyWrapper>
      }
      placement="right"
      enterTouchDelay={0}
    >
      <Box display="flex" alignItems="center">
        <IconInfo height="12" width="12" />
      </Box>
    </Tooltip>
  </Box>
);

const ShippingLineItem: FC<ShippingLineItemProps> = (props) => {
  const { lineItems } = props;

  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  const { shippingConfig } = useShopConfig();
  const shouldInsureAllPurchases = shippingConfig?.shouldInsureAllPurchases ?? false;

  const shippingTotal = getShippingTotal(lineItems) / 100;
  const shippingLineItems = lineItems.filter((lineItem) => lineItem.code === LineItemCode.Shipping);
  const firstShippingCost = shippingLineItems[0]?.shippingLineItem?.price;

  // Exclude shouldInsureAllPurchases because it dynamically changes with item cost
  const areAllShippingCostsSame =
    shippingLineItems.reduce(
      (acc, lineItem) => acc && firstShippingCost === lineItem.shippingLineItem?.price,
      true
    ) && !shouldInsureAllPurchases;
  const shippingBreakdown =
    !isNil(firstShippingCost) && shippingLineItems.length > 1 && areAllShippingCostsSame
      ? ` (${formatDollarAmount(intl, firstShippingCost, currencyConfig)} × ${
          shippingLineItems.length
        })`
      : '';

  const numListings = lineItems.filter((lineItem) => lineItem.code === LineItemCode.Listing).length;
  const noBundledShippingTotal = firstShippingCost ? (numListings * firstShippingCost) / 100 : 0;
  const savedShippingCost = areAllShippingCostsSame ? noBundledShippingTotal - shippingTotal : 0;
  const isSignedDelivery =
    shippingLineItems[0]?.name === SIGNATURE_CONFIRMATION_SHIPPING_LINE_ITEM_NAME;

  const shippingTotalText =
    shippingTotal > 0 ? intl.formatNumber(shippingTotal, currencyConfig) : 'Free';

  return (
    <LineItemWrapper>
      <div>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="body2" style={{ fontWeight: 'bold' }} display="inline">
            {shippingLineItems[0]?.name || 'Shipping'}
          </Typography>
          {(isSignedDelivery || shouldInsureAllPurchases) && (
            <ShippingToolTip
              text={`${
                isSignedDelivery ? 'This shipment requires a signature upon delivery.' : ''
              }${isSignedDelivery && shouldInsureAllPurchases ? ' ' : ''}${
                shouldInsureAllPurchases ? 'All shipments are insured' : ''
              }`}
            />
          )}
        </Box>
        <Typography variant="body2" display="inline">
          {shippingBreakdown}
        </Typography>
        {savedShippingCost > 0 && (
          <Typography
            variant="body2"
            style={{ color: defaultTreetStyles.gray60, fontStyle: 'italic' }}
          >
            You saved {intl.formatNumber(savedShippingCost, currencyConfig)} on bundle shipping
          </Typography>
        )}
      </div>
      <Typography variant="body2" style={{ textAlign: 'end' }}>
        {!isEmpty(shippingLineItems) ? shippingTotalText : 'Calculated based on shipping address'}
      </Typography>
    </LineItemWrapper>
  );
};

interface TaxLineItemProps {
  taxTotal: number;
  name?: string;
}

const TaxLineItem: FC<TaxLineItemProps> = (props) => {
  const { taxTotal, name } = props;

  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <LineItemWrapper>
      <Typography variant="body2" style={{ fontWeight: 'bold' }} display="inline">
        {name || 'Tax'}
      </Typography>
      <Typography variant="body2" style={{ textAlign: 'end' }}>
        {intl.formatNumber(taxTotal, currencyConfig)}
      </Typography>
    </LineItemWrapper>
  );
};

interface RefundTotalLineItemProps {
  refundTotal: number;
}

const RefundTotalLineItem: FC<RefundTotalLineItemProps> = ({ refundTotal }) => {
  const intl = useIntl();
  const { currencyConfig } = useBrandCountryConfig();

  return (
    <LineItemWrapper>
      <Typography variant="body2" style={{ fontWeight: 'bold' }} display="inline">
        Refund Total
      </Typography>
      <Typography variant="body2" style={{ textAlign: 'end' }}>
        -{intl.formatNumber(refundTotal, currencyConfig)}
      </Typography>
    </LineItemWrapper>
  );
};

interface LineItemBreakdownProps {
  lineItems: CheckoutLineItem[];
  participant: Participant;
  bundleType: BundleType;
  refunds?: CoreRefund[];
  shouldCombineItemsAndShipping?: boolean;
}

const LineItemBreakdown: FC<LineItemBreakdownProps> = (props: LineItemBreakdownProps) => {
  const {
    lineItems: allLineItems,
    participant,
    bundleType,
    refunds,
    shouldCombineItemsAndShipping = false,
  } = props;

  const intl = useIntl();
  const { css: brandCss } = useShopConfig();
  const { currencyConfig } = useBrandCountryConfig();
  const { isTaxEnabled } = useTax();

  const bgcolor = brandCss?.backgroundColor || 'white';

  // Only show the line items that is relevant for the participant
  const lineItems = allLineItems.filter((lineItem) => lineItem.includeFor.includes(participant));
  const refundedLineItemIds = flatten(
    refunds?.map((refund) => refund.refundedLineItems.map((item) => item.id)) || []
  );
  const refundedLineItems = lineItems.filter(
    (lineItem) => lineItem.id && refundedLineItemIds.includes(lineItem.id)
  );
  const totalRefundPrice = calculateLineItemTotal(refundedLineItems);

  const isBuyer = participant === Participant.Buyer;
  const listingsTotal = getListingsTotal(lineItems) / 100;
  const shippingTotal = getShippingTotal(lineItems) / 100;
  const discountTotal = getDiscountTotal(lineItems) / 100;
  const returnInsuranceTotal = getReturnInsuranceTotal(lineItems) / 100;
  const taxTotal = getTaxTotal(lineItems) / 100;
  const refundTotal = totalRefundPrice / 100;
  const finalTotal = calculateLineItemTotal(lineItems) / 100 - refundTotal;

  // There should only be one discount applied
  const discountItem = lineItems.find(
    (lineItem) => lineItem.code === LineItemCode.Discount
  )?.discountLineItem;
  const returnInsuranceLineItem = lineItems.find(
    (lineItem) => lineItem.code === LineItemCode.ReturnInsurance
  );

  // Shipping may not be known if a user is on the checkout page and has not entered
  // their shipping address
  const hasShippingLineItems = !isEmpty(
    lineItems.filter((lineItem) => lineItem.code === LineItemCode.Shipping)
  );
  const itemsAndShippingTotal = listingsTotal + shippingTotal;
  const taxLineItem = lineItems.find((lineItem) => lineItem.code === LineItemCode.Tax);

  return (
    <Box display="flex" flexDirection="column" bgcolor={bgcolor}>
      {shouldCombineItemsAndShipping && (
        <ItemsAndShippingLineItem
          hasShippingLineItems={hasShippingLineItems}
          shippingTotal={shippingTotal}
          itemsAndShippingTotal={itemsAndShippingTotal}
        />
      )}
      {!shouldCombineItemsAndShipping && (
        <>
          <ItemsLineItem listingsTotal={listingsTotal} />
          <Divider />
          {isBuyer && bundleType === BundleType.Marketplace && (
            <>
              <ShippingLineItem lineItems={lineItems} />
              <Divider />
            </>
          )}
        </>
      )}
      {!!discountTotal && (
        <div key={discountItem?.title}>
          <LineItemWrapper>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              {discountItem?.title}
            </Typography>
            <Typography variant="body2">
              -{intl.formatNumber(discountTotal, currencyConfig)}
            </Typography>
          </LineItemWrapper>
          <Divider />
        </div>
      )}
      {!!returnInsuranceTotal && (
        <>
          <LineItemWrapper>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              {returnInsuranceLineItem?.name}
            </Typography>
            <Typography variant="body2" style={{ textAlign: 'end' }}>
              <DynamicValueWrapper>
                {intl.formatNumber(returnInsuranceTotal, currencyConfig)}
              </DynamicValueWrapper>
            </Typography>
          </LineItemWrapper>
          <Divider />
        </>
      )}
      {isTaxEnabled && (
        <>
          <TaxLineItem taxTotal={taxTotal} name={taxLineItem?.name} />
          <Divider />
        </>
      )}
      {!!refundTotal && (
        <>
          <RefundTotalLineItem refundTotal={refundTotal} />
          <Divider />
        </>
      )}
      <LineItemWrapper>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          Total
        </Typography>
        <Typography variant="body2" style={{ color: defaultTreetStyles.red60, fontWeight: 'bold' }}>
          <DynamicValueWrapper>{intl.formatNumber(finalTotal, currencyConfig)}</DynamicValueWrapper>
        </Typography>
      </LineItemWrapper>
    </Box>
  );
};

export default LineItemBreakdown;
