/* eslint-disable default-case */
/* eslint-disable camelcase */
import { capitalize, compact, isEmpty } from 'lodash';
import { keywordFilterConfig } from '../shopConfig/filters/marketplace-custom-config';
import {
  Filter,
  FilterId,
  FrenzySortOptions,
  GenericFilterOption,
  SearchFilterType,
  SearchNavBarSections,
} from '../types/filters/filters';
import { Facet, FacetFields, FrenzyFacets } from '../types/frenzy/facet';
import { EntityRef } from '../types/sharetribe/uuid';
import { handle } from './helpers';
import { search as searchApiRequest } from './api';
import { FilterQueryParam, SearchParams } from '../types/search/search';
import { Conditions, getConditionsFilterConfig } from '../shopConfig/filters/condition';
import { getSizeFilterConfigFromOptions } from '../shopConfig/filters/size';
import { getBrandDirectFilterConfig } from '../shopConfig/filters/brandDirect';
import { getCategoryFilterConfig, getStyleFilterConfig } from '../shopConfig/filters/styles';
import { getGenderFilterConfig } from '../shopConfig/filters/gender';
import { FrenzyApiModes } from '../types/frenzy/query';
import { getColorFilterConfig } from '../shopConfig/filters/color';
import { getInseamFilterConfig } from '../shopConfig/filters/inseam';
import { getDecadeFilterConfig } from '../shopConfig/filters/decade';
import { getVintageFilterConfig } from '../shopConfig/filters/vintage';

interface GetDataFromFrenzySearchInputs {
  subdomain: string;
  searchParams: SearchParams;
  filtersConfig: Filter[];
  origin?: string;
  userId?: string;
}

interface FrenzyFilterParams {
  [FrenzyFacets.Condition]?: string[];
  [FrenzyFacets.BrandDirect]?: string[];
  [FrenzyFacets.Size]?: string[];
}

interface GetFilterConfigInputs {
  options: GenericFilterOption[];
}

interface BuildFilterBase {
  filterFacets?: Facet[];
  getFilterConfigFn: (inputs: GetFilterConfigInputs) => Filter;
}
interface BuildFilterFromFrenzyFacetsInputs extends BuildFilterBase {
  labelTransform?: (filterKey: string) => string;
}

interface BuildFilterFromConfigOptionsInputs extends BuildFilterBase {
  filtersConfig: Filter[];
  filterId: FilterId;
  frenzyToTreetFilterMapping?: { [key: string]: any };
}

const FRENZY_CONDITION_TO_TREET_CONDITION: { [key: string]: Conditions } = {
  newwithtags: Conditions.NewWithTags,
  newwithouttags: Conditions.NewWithoutTags,
  minordefect: Conditions.MinorDefect,
  excellent: Conditions.Excellent,
  good: Conditions.Good,
};

const getFrenzyFacetFromTreetFilter = (filterId: FilterId, shouldUseTreetOptions: boolean) => {
  const facetToFilter: { [key in FilterId]?: FrenzyFacets } = {
    [FilterId.Size]: FrenzyFacets.Size,
    [FilterId.Condition]: FrenzyFacets.Condition,
    [FilterId.IsBrandDirect]: FrenzyFacets.BrandDirect,
    [FilterId.Category]: shouldUseTreetOptions ? FrenzyFacets.TreetCategory : FrenzyFacets.Category,
    [FilterId.Style]: shouldUseTreetOptions ? FrenzyFacets.TreetStyle : FrenzyFacets.Style,
    [FilterId.Color]: FrenzyFacets.Color,
    [FilterId.Gender]: FrenzyFacets.TreetGender,
    [FilterId.Inseam]: FrenzyFacets.TreetInseam,
    [FilterId.Decade]: FrenzyFacets.TreetDecade,
    [FilterId.Vintage]: FrenzyFacets.TreetVintage,
  };
  return facetToFilter[filterId];
};

// Modeled off of sortConfig in marketplace-custom-config.js
export const sortConfigForFrenzy = {
  id: 'sort',
  active: true,
  queryParamName: 'sort',
  conflictingFilters: [],
  type: SearchFilterType.Sort,
  searchNavBarSection: SearchNavBarSections.Sort,

  options: [
    { key: FrenzySortOptions.BestMatch, label: 'Most Relevant' },
    { key: FrenzySortOptions.Newest, label: 'Newest' },
    { key: FrenzySortOptions.LowestPrice, label: 'Lowest Price' },
    { key: FrenzySortOptions.HighestPrice, label: 'Highest Price' },
  ],
};

// Build filter using frenzy facets. Each frenzy facet filter option is gauranteed to have results.
const buildFilterFromFrenzyFacets = (params: BuildFilterFromFrenzyFacetsInputs): Filter | null => {
  const { filterFacets = [], getFilterConfigFn, labelTransform } = params;
  const options = filterFacets.map(([filterKey, filterCount]) => ({
    key: filterKey,
    label: labelTransform ? labelTransform(filterKey) : filterKey,
    count: filterCount,
  }));
  return !isEmpty(options) ? getFilterConfigFn({ options }) : null;
};

// Build filter using options from the treet filter config. If there are no frenzy results for an
// option, mark it as disabled.
const buildFilterFromConfigOptions = (params: BuildFilterFromConfigOptionsInputs): Filter => {
  const {
    filterFacets = [],
    filtersConfig,
    filterId,
    getFilterConfigFn,
    frenzyToTreetFilterMapping = {},
  } = params;

  const filterConfig = filtersConfig.find((filter) => filter.id === filterId);
  const shouldHideNoResultOptions = filterConfig?.config.shouldHideNoResultOptions;

  // Map options to the filter config, so that we can show disabled values.
  const filterConfigOptions = filterConfig?.config.options || [];
  const options = filterConfigOptions.reduce((acc, configOption) => {
    const frenzyFacet = filterFacets.find(
      ([filterKey]) => (frenzyToTreetFilterMapping[filterKey] ?? filterKey) === configOption.key
    );
    const isNoResultOption = !frenzyFacet?.[1];
    const updatedFilterOption = {
      ...configOption,
      // If there are no results, mark the option as disabled
      ...(isNoResultOption ? { count: 0, disabled: true } : { count: frenzyFacet?.[1] }),
    };

    const shouldShowOption =
      updatedFilterOption &&
      (!isNoResultOption || (isNoResultOption && !shouldHideNoResultOptions));
    return shouldShowOption ? [...acc, updatedFilterOption] : acc;
  }, [] as GenericFilterOption[]);

  return getFilterConfigFn({ options });
};

const getFacetsFromFilters = (filters: Filter[]): FrenzyFacets[] => {
  const facets = filters.map((filter) => {
    const { id, shouldUseTreetOptions = false } = filter;
    return getFrenzyFacetFromTreetFilter(id, shouldUseTreetOptions);
  });

  // Remove null values
  return compact(facets);
};

export const buildFiltersFromFrenzyData = (
  facets: FacetFields,
  filtersConfig: Filter[]
): Filter[] => {
  const filters: Filter[] = [];
  const validFrenzyFacets = getFacetsFromFilters(filtersConfig);

  validFrenzyFacets.forEach((facetKey) => {
    switch (facetKey) {
      case FrenzyFacets.Size: {
        const sizeFilterConfig = buildFilterFromConfigOptions({
          filterFacets: facets[FrenzyFacets.Size],
          filtersConfig,
          filterId: FilterId.Size,
          getFilterConfigFn: getSizeFilterConfigFromOptions,
        });
        filters.push(sizeFilterConfig);
        break;
      }
      case FrenzyFacets.Condition: {
        const conditionsFilterConfig = buildFilterFromConfigOptions({
          filterFacets: facets[FrenzyFacets.Condition],
          filtersConfig,
          filterId: FilterId.Condition,
          getFilterConfigFn: getConditionsFilterConfig,
          frenzyToTreetFilterMapping: FRENZY_CONDITION_TO_TREET_CONDITION,
        });
        filters.push(conditionsFilterConfig);
        break;
      }
      case FrenzyFacets.Category: {
        const categoryFilterConfig = buildFilterFromFrenzyFacets({
          getFilterConfigFn: getCategoryFilterConfig,
          labelTransform: capitalize,
          filterFacets: facets[FrenzyFacets.Category],
        });
        if (categoryFilterConfig) filters.push(categoryFilterConfig);
        break;
      }
      case FrenzyFacets.TreetCategory: {
        const categoryFilterConfig = buildFilterFromConfigOptions({
          getFilterConfigFn: getCategoryFilterConfig,
          filtersConfig,
          filterId: FilterId.Category,
          filterFacets: facets[FrenzyFacets.TreetCategory],
        });
        filters.push(categoryFilterConfig);
        break;
      }
      case FrenzyFacets.Style: {
        const styleFilterConfig = buildFilterFromFrenzyFacets({
          getFilterConfigFn: getStyleFilterConfig,
          labelTransform: capitalize,
          filterFacets: facets[FrenzyFacets.Style],
        });
        if (styleFilterConfig) filters.push(styleFilterConfig);
        break;
      }
      case FrenzyFacets.TreetStyle: {
        const styleFilterConfig = buildFilterFromConfigOptions({
          getFilterConfigFn: getStyleFilterConfig,
          filtersConfig,
          filterId: FilterId.Style,
          filterFacets: facets[FrenzyFacets.TreetStyle],
        });
        filters.push(styleFilterConfig);
        break;
      }
      case FrenzyFacets.BrandDirect: {
        const brandDirectFilter = buildFilterFromConfigOptions({
          filterFacets: facets[FrenzyFacets.BrandDirect],
          filtersConfig,
          filterId: FilterId.IsBrandDirect,
          getFilterConfigFn: getBrandDirectFilterConfig,
        });
        filters.push(brandDirectFilter);
        break;
      }
      case FrenzyFacets.TreetGender: {
        const genderFilter = buildFilterFromFrenzyFacets({
          filterFacets: facets[FrenzyFacets.TreetGender],
          getFilterConfigFn: getGenderFilterConfig,
        });
        if (genderFilter) filters.push(genderFilter);
        break;
      }
      case FrenzyFacets.Color: {
        const colorFilterConfig = buildFilterFromFrenzyFacets({
          getFilterConfigFn: getColorFilterConfig,
          labelTransform: capitalize,
          filterFacets: facets[FrenzyFacets.Color],
        });
        if (colorFilterConfig) filters.push(colorFilterConfig);
        break;
      }
      case FrenzyFacets.TreetInseam: {
        const inseamFilterConfig = buildFilterFromFrenzyFacets({
          getFilterConfigFn: getInseamFilterConfig,
          labelTransform: capitalize,
          filterFacets: facets[FrenzyFacets.TreetInseam],
        });
        if (inseamFilterConfig) filters.push(inseamFilterConfig);
        break;
      }
      case FrenzyFacets.TreetDecade: {
        const decadeFilterConfig = buildFilterFromFrenzyFacets({
          getFilterConfigFn: getDecadeFilterConfig,
          labelTransform: capitalize,
          filterFacets: facets[FrenzyFacets.TreetDecade],
        });
        if (decadeFilterConfig) filters.push(decadeFilterConfig);
        break;
      }
      case FrenzyFacets.TreetVintage: {
        const vintageFilterConfig = buildFilterFromFrenzyFacets({
          getFilterConfigFn: getVintageFilterConfig,
          labelTransform: capitalize,
          filterFacets: facets[FrenzyFacets.TreetVintage],
        });
        if (vintageFilterConfig) filters.push(vintageFilterConfig);
        break;
      }
    }
  });

  filters.push(keywordFilterConfig);
  return filters;
};

export const buildFrenzyFilterParams = (
  searchParams: SearchParams,
  filtersConfig: Filter[]
): FrenzyFilterParams => {
  const {
    pub_condition,
    pub_isBrandDirect,
    pub_size,
    pub_category,
    style,
    gender,
    color,
    inseam,
    decade,
    vintage,
  } = searchParams;

  const { shouldUseTreetOptions: shouldUseTreetStyleOptions = false } =
    filtersConfig.find((filter) => filter.id === FilterId.Style) || {};
  const styleFacet = shouldUseTreetStyleOptions ? FrenzyFacets.TreetStyle : FrenzyFacets.Style;

  const { shouldUseTreetOptions: shouldUseTreetCategoryOptions = false } =
    filtersConfig.find((filter) => filter.id === FilterId.Category) || {};
  const categoryFacet = shouldUseTreetCategoryOptions
    ? FrenzyFacets.TreetCategory
    : FrenzyFacets.Category;

  return {
    ...(pub_condition !== undefined && {
      [FrenzyFacets.Condition]: pub_condition.toString().split(','),
    }),
    ...(pub_isBrandDirect !== undefined && {
      [FrenzyFacets.BrandDirect]: pub_isBrandDirect.toString().split(','),
    }),
    ...(pub_size !== undefined && { [FrenzyFacets.Size]: pub_size.toString().split(',') }),
    ...(pub_category !== undefined && {
      [categoryFacet]: pub_category.toString().split(','),
    }),
    ...(style !== undefined && { [styleFacet]: style.toString().split(',') }),
    ...(gender !== undefined && { [FrenzyFacets.TreetGender]: gender.toString().split(',') }),
    ...(color !== undefined && { [FrenzyFacets.Color]: color.toString().split(',') }),
    ...(inseam !== undefined && { [FrenzyFacets.TreetInseam]: inseam.toString().split(',') }),
    ...(decade !== undefined && { [FrenzyFacets.TreetDecade]: decade.toString().split(',') }),
    ...(vintage !== undefined && { [FrenzyFacets.TreetVintage]: vintage.toString().split(',') }),
  };
};

export const getFrenzyInternalFilters = (
  filters: Filter[],
  frenzyFilters: { [filterKey: string]: string[] }
) => {
  // Remove Treet user surfaced filters, so we only return Frenzy internal filters that were
  // automatically applied. This state needs to be saved and passed back into subsequent Frenzy
  // filter change requests.
  const frenzyFiltersForTreet = getFacetsFromFilters(filters);
  frenzyFiltersForTreet.forEach((filterKey) => {
    if (filterKey in frenzyFilters) {
      // eslint-disable-next-line no-param-reassign
      delete frenzyFilters[filterKey];
    }
  });
  return frenzyFilters;
};

export const cleanSearchParamsForFrenzy = (searchParams: { [key: string]: any }) => {
  const filtersToPreserve = [FilterQueryParam.sort, FilterQueryParam.keywords]; // Preserve certain filters across searches
  const filtersToRemove = Object.values(FilterQueryParam).filter(
    (filterQueryParam) => !filtersToPreserve.includes(filterQueryParam)
  ) as string[];
  return Object.keys(searchParams)
    .filter((key) => !filtersToRemove.includes(key))
    .reduce((cur, key) => Object.assign(cur, { [key]: searchParams[key] }), {});
};

// Sort list of ID objects based on the ordering of another list
export const orderIdsByList = (list: EntityRef[], order: string[]) =>
  list.sort((a, b) => order.indexOf(a.id.uuid) - order.indexOf(b.id.uuid));

export const updateFrenzySearchMode = (mode: FrenzyApiModes, filters: FrenzyFilterParams) => {
  if (!filters && mode === FrenzyApiModes.FilterChange) {
    // If the user has deselected all filters, treat this as raw query search
    return FrenzyApiModes.RawQuery;
  }
  // Set default search mode to be raw-query
  return mode || FrenzyApiModes.RawQuery;
};

export const getDataFromFrenzySearch = async (params: GetDataFromFrenzySearchInputs) => {
  const { subdomain, searchParams, filtersConfig, origin, userId } = params;

  const { page, perPage, mode, sort, keywords, ...rest } = searchParams;
  const filters = buildFrenzyFilterParams(searchParams, filtersConfig);
  const frenzySearchMode = updateFrenzySearchMode(mode as FrenzyApiModes, filters);

  const [searchResponse, searchError] = await handle(
    searchApiRequest(
      {
        subdomain,
        searchQuery: keywords || '',
        perPage,
        page,
        filters,
        mode: frenzySearchMode,
        sort: sort || FrenzySortOptions.BestMatch,
        userId,
      },
      origin
    )
  );

  if (searchError) {
    // Fail gracefully, since we will fall back to sharetribe search querying.
    // This error will already be logged in server/routes/searchRouter.ts.
    return {};
  }

  const sharetribeListingIds = searchResponse.sku_results;
  return {
    ...(sharetribeListingIds && {
      params: {
        ids: sharetribeListingIds.join(','),
        ...cleanSearchParamsForFrenzy(rest),
      },
    }),
    pagination: {
      page,
      perPage,
      totalItems: searchResponse.products_found,
      totalPages: searchResponse.page_count,
    },
    filters: buildFiltersFromFrenzyData(searchResponse.facet_fields, filtersConfig),
    // TODO (anniew|TREET-1643) Update to optimize frenzy state preservation by passing in frenzy
    // internal filters. Currently no relevant frenzy filters (for frenzy's internal use only) are
    // being stored.
    frenzyFilters: getFrenzyInternalFilters(filtersConfig, searchResponse.filters),
  };
};
