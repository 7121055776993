import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const industryofallnationsSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'P',
  '2/3',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '26',
  '28',
  '30',
  '32',
  '34',
  '36',
];

export const industryofallnationsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: industryofallnationsSizes,
    }),
  ],
};
