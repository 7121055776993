import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink, TypographyWrapper } from '..';
import { useIsMobile } from '../../hooks/useIsMobile';
import { defaultTreetStyles } from '../../shopConfig/config';

import css from './TabNav.module.css';

const Tab = (props) => {
  const { className, id, disabled, text, selected, linkProps, a } = props;
  const isMobile = useIsMobile();
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
  });

  const body = (
    // Sidebar becomes a topbar on mobile, so font sizes changes */}
    <TypographyWrapper
      variant={isMobile ? 'body1' : 'h2'}
      typographyOverrides={{
        style: { color: disabled ? defaultTreetStyles.gray60 : 'inherit' },
      }}
    >
      {text}
    </TypographyWrapper>
  );

  if (a?.href) {
    return (
      <div id={id} className={className}>
        <a className={linkClasses} {...a}>
          {body}
        </a>
      </div>
    );
  }

  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {body}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = (props) => {
  const { className, rootClassName, tabRootClassName, tabs, getMore } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return <Tab key={id} id={id} className={tabClasses} {...tab} />;
      })}
      {getMore}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  getMore: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
  getMore: React.ReactElement,
};

export default TabNav;
