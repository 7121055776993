import classNames from 'classnames';
import PropTypes, { func, node, string } from 'prop-types';
import React, { useState } from 'react';

import css from './IconButton.module.css';

const IconButton = (props) => {
  const { className, icon, hoverIcon, onClick, styleOverride, ariaLabel } = props;
  const classes = classNames(css.root, className);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      style={styleOverride}
      className={classes}
      onClick={onClick}
      aria-label={ariaLabel}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      type="button"
    >
      {isHovered && hoverIcon ? hoverIcon : icon}
    </button>
  );
};

IconButton.defaultProps = {
  className: null,
  styleOverride: {},
  ariaLabel: null,
};

IconButton.propTypes = {
  className: string,
  icon: node,
  hoverIcon: node,
  onClick: func,
  styleOverride: PropTypes.object,
  ariaLabel: string,
};

export default IconButton;
