import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const hopeandplumSizes = [
  'Adult',
  'Kids',
  '0-6 months',
  '6-12 months',
  '1-2 years',
  '2-4 years',
  'Long',
  'Short',
  'Long (40" to 61")',
  'Long (XL to 6X)',
  'Short (28" to 39")',
  'Short (XXS to L)',
  '74',
  '84',
  '94',
];

export const hopeandplumShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({
      sizes: hopeandplumSizes,
    }),
  ],
};
