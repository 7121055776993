import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { useShopConfig } from '../../hooks/shopConfig';
import { FontConfigSection } from '../../types/shopConfig/shopConfigV2';
import { ValidationError } from '..';

import css from './FieldSelect.module.css';

const FieldSelectComponent = (props) => {
  const { selectClassName, className, id, label, description, input, meta, children, ...rest } =
    props;

  const theme = useTheme();
  const { fontConfig } = useShopConfig();
  const labelTypographyVariant = fontConfig[FontConfigSection.FieldLabel];

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(css.select, selectClassName, {
    [css.selectSuccess]: valid,
    [css.selectError]: hasError,
  });
  const selectProps = { className: selectClasses, id, ...input, ...rest };

  const classes = classNames(css.root, className);

  const body1Font = theme?.typography?.body1;

  // Needed because we can't apply !important in inline styles
  let overrideRef;

  // This is the only way to set the !important flag in React
  // TODO (sonia-y | TREET-1281): Remove this piece of code once we no longer use the fonts configured from config.js
  if (body1Font?.fontFamily) {
    overrideRef = (element) => {
      const sanitizedFontFamily = body1Font.fontFamily.replace('!important', '');
      if (element?.style)
        element.style.setProperty('font-family', sanitizedFontFamily, 'important');
    };
  }

  const childrenWithStyle = React.Children.map(
    children,
    (child) => child && React.cloneElement(child, { style: body1Font })
  );

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={id}>
          <TypographyWrapper
            variant={labelTypographyVariant}
            typographyOverrides={{ style: { fontWeight: 'bold' } }}
          >
            {label}
          </TypographyWrapper>
        </label>
      ) : null}
      {/* Typography doesn't work well with inputs so need to adjust font this way */}
      {/* Update to always use body1FontStyle once Feature.TreetV2 is fully enabled */}
      <select {...selectProps} style={body1Font} ref={overrideRef}>
        {childrenWithStyle}
      </select>
      <ValidationError fieldMeta={meta} />
      {/* Render description below label if provided */}
      {description && (
        <TypographyWrapper variant="body2" typographyOverrides={{ style: { marginTop: '4px' } }}>
          {description}
        </TypographyWrapper>
      )}
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  description: null, // Add description to default props
  children: null,
};

const { string, object, node } = PropTypes;

FieldSelectComponent.propTypes = {
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  description: string, // Add description prop type

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldSelect = (props) => <Field component={FieldSelectComponent} {...props} />;

export default FieldSelect;
